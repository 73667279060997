export enum UserRolesEnum {
  ADMIN = 'org:admin',
  MEMBER = 'org:member',
  THERAPIST = 'org:therapist',
}

export type ShamefulAny = any;
export enum VfScenarioEnum {
  JOURNAL = 'journal',
  MEMORY = 'memory',
  OTHER = 'other',
}

export interface UserVFState {
  userRole: UserRolesEnum;
  vfScenario: VfScenarioEnum;
}
