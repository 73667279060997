import React from 'react';
import { DailyVideo, useVideoTrack } from '@daily-co/daily-react';
import Username from '../UserName/UserName';
import { TileContainer } from './styled';

export interface TileProps {
  id: string;
  isScreenShare?: boolean;
  isLocal?: boolean;
  isAlone?: boolean;
}

const Tile = ({ id, isScreenShare, isLocal, isAlone }: TileProps): JSX.Element => {
  const videoState = useVideoTrack(id);

  const containerCssClasses = [isScreenShare ? 'tile-screenshare' : 'tile-video'];

  if (isLocal) {
    containerCssClasses.push('self-view');
    if (isAlone) {
      containerCssClasses.push('alone');
    }
  }

  /* If a participant's video is muted, hide their video and
  add a different background color to their tile. */
  if (videoState.isOff) {
    containerCssClasses.push('no-video');
  }

  return (
    <TileContainer className={containerCssClasses.join(' ')}>
      <DailyVideo automirror sessionId={id} type={isScreenShare ? 'screenVideo' : 'video'} />
      {!isScreenShare && <Username id={id} isLocal={isLocal} />}
    </TileContainer>
  );
};

export default Tile;
