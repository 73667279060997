import { SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { MicrophoneIcon } from '../../icons';
import { breakpoints } from '../../styled';

export const MicrophoneSelectorWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 20px;
  z-index: 5;
  padding: 0 3px;
  background: #d3d3d3;
  opacity: 0.7;
  border-radius: 4px;
  @media ${breakpoints.tablet} {
    display: none;
  }
`;
export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
  }
`;
export const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  max-width: 200px;
  width: 100%;
  border-radius: 12px;
  color: #333;
  opacity: 0.8;
`;
export const MicrophoneItem = styled.div`
  display: inline-block;
  flex-direction: row;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  direction: ltr;
  cursor: pointer;
  border-radius: 12px;
  padding: 5px;
  &.selected {
    color: #007bff;
  }
  &:hover {
    background: #f6f6f6;
  }
`;

const MicrophoneSelector = ({ setMicrophone, microphone, updateShenai }: { setMicrophone: (x: string) => void; microphone: string | null; updateShenai: any }) => {
  const [audioDevicesList, setAudioDevicesList] = useState<MediaDeviceInfo[]>([]);
  const [microphoneId, setMicrophoneId] = useState<string>('');
  const [isMicrophoneSelectorHidden, setMicrophoneSelectorIsHidden] = useState<boolean>(false);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((res) => {
      const audioDeviceList = res?.filter((el) => {
        return el.kind === 'audioinput';
      });
      setAudioDevicesList(audioDeviceList);
    });
    if (audioDevicesList.length > 0) {
      microphone ? setMicrophoneId(microphone) : setMicrophoneId(audioDevicesList[0].deviceId);
    }
  }, [isMicrophoneSelectorHidden]);

  const handleMicrophoneChange = (e: any) => {
    setMicrophoneSelectorIsHidden(false);
    setMicrophone(e.target.getAttribute('data-value'));
    setMicrophoneId(e.target.getAttribute('data-value'));
    updateShenai?.selectCameraByDeviceId(e.target.getAttribute('data-value'), true);
    localStorage.setItem('microphoneId', `${e.target.getAttribute('data-value')}`);
  };
  return (
    <MicrophoneSelectorWrapper>
      {isMicrophoneSelectorHidden && (
        <Wrapper>
          {audioDevicesList ? (
            audioDevicesList.map((device: MediaDeviceInfo, index: number) => {
              return (
                <MicrophoneItem
                  className={device.deviceId == microphoneId ? 'selected' : ''}
                  key={index}
                  data-value={device.deviceId}
                  onClick={handleMicrophoneChange}
                >
                  {device.label}
                </MicrophoneItem>
              );
            })
          ) : (
            <>No devices available</>
          )}
        </Wrapper>
      )}
      <IconWrapper>
        <MicrophoneIcon onClick={() => setMicrophoneSelectorIsHidden(!isMicrophoneSelectorHidden)} />
      </IconWrapper>
    </MicrophoneSelectorWrapper>
  );
};

export default MicrophoneSelector;
