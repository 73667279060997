import styled from 'styled-components';
import { errorIcon } from '../../../images';

const ErrorBoundaryFallbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 100%;
  align-self: center;
  img {
    width: 60px;
    height: 60px;
  }
  h3 {
    color: #ff0000;
  }
  button {
    width: 150px;
    text-wrap: nowrap;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 16px 32px;
    display: inline-block;
    padding: 0.5rem;
    border-radius: 0.7rem;
    background: transparent;
    color: #333;
    border: none;
    font-size: 16px;
    &:focus,
    &:active,
    &:hover,
    &:focus-visible {
      outline: none;
      border: none;
    }
    &:hover {
      color: #777;
    }
  }
  a {
    color: #333;
    &:hover {
      color: #777;
    }
  }
`;

const ErrorBoundaryFallback = () => {
  const handleReloadClick = () => {
    location.reload();
  };
  return (
    <ErrorBoundaryFallbackWrapper>
      <img src={errorIcon} />
      <h3>OOps something go wrong</h3>
      <button onClick={handleReloadClick}>Reload Page</button>
      <button>
        <a href="/">Return to Chat</a>
      </button>
    </ErrorBoundaryFallbackWrapper>
  );
};
export default ErrorBoundaryFallback;
