import React, { useContext } from 'react';
import { ShenaiSDK, MeasurementState } from 'shenai-sdk';
import { type ShenaiSdkState, getStatusForBioString } from './utils';
import { HeartIcon } from '../../icons';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../contextApp';
import { BioStatusBlockShort, BioStatusCard } from './bioModule.styled';

interface ShortBioInfoBlockProps {
  sdkState: ShenaiSdkState | undefined;
  shenaiSDK: ShenaiSDK | null;
  isMini?: boolean;
}

const ShortBioInfoBlock: React.FC<ShortBioInfoBlockProps> = ({ sdkState, shenaiSDK, isMini }) => {
  const { i18n } = useTranslation();
  const { direction } = useContext(AppContext)!;
  const getShortBioClass = (val: MeasurementState | undefined) => {
    switch (val) {
      case shenaiSDK?.MeasurementState.NOT_STARTED:
      case shenaiSDK?.MeasurementState.WAITING_FOR_FACE:
      case shenaiSDK?.MeasurementState.RUNNING_SIGNAL_BAD_DEVICE_UNSTABLE:
      case shenaiSDK?.MeasurementState.RUNNING_SIGNAL_SHORT:
        return 'idle';
      case shenaiSDK?.MeasurementState.RUNNING_SIGNAL_BAD:
      case shenaiSDK?.MeasurementState.FAILED:
        return 'error';
      case shenaiSDK?.MeasurementState.RUNNING_SIGNAL_GOOD:
      case shenaiSDK?.MeasurementState.FINISHED:
        return 'success';
      default:
        return '';
    }
  };

  return (
    <BioStatusBlockShort className={isMini ? 'bio-status-mini' : ''}>
      <BioStatusCard className="heartbeat-block">
        <div className={sdkState?.faceState === shenaiSDK?.FaceState.OK ? 'active' : 'disabled'}>
          <div className={direction == 'ltr' ? 'heartbeat-block-number left' : 'heartbeat-block-number'}>
            <span className={sdkState?.faceState === shenaiSDK?.FaceState.OK ? 'active' : 'disabled'}>
              {sdkState?.hr10s ? `${sdkState.hr10s.toFixed(0)}` : ''}
            </span>
            <span className={'unit'}>{sdkState?.hr10s ? ' bpm' : '...'}</span>
          </div>
        </div>
        <div className={sdkState?.faceState === shenaiSDK?.FaceState.OK ? 'active' : 'disabled'}>
          <div className={'heartIcon'}>
            <HeartIcon />
          </div>
        </div>
      </BioStatusCard>
      <BioStatusCard className="bio-status-block">
        <div className={'outputValue'}>
          <span>{sdkState && getStatusForBioString(shenaiSDK, sdkState.measurementState, sdkState.faceState, i18n.language)}</span>
        </div>
        <div>
          <span className={`circle ${getShortBioClass(sdkState?.measurementState)}`}></span>
        </div>
      </BioStatusCard>
    </BioStatusBlockShort>
  );
};

export default ShortBioInfoBlock;
