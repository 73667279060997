import { useState } from 'react';
import { useRuntime } from '../../packages/react-chat';
import PatientOfficeBlock from '../components/officeMode/PatientOfficeBlock';

interface ISendMessageProps {
  runtime: ReturnType<typeof useRuntime>;
}

export const PatientOfficeMode = (props: ISendMessageProps) => {
  const { runtime } = props;
  const [showPatientOffice, setShowPatientOffice] = useState(true);

  //   runtime.interact({ type: 'patient_office_mode_done', payload: '' });

  return (
    <div>
      {showPatientOffice ? (
        <PatientOfficeBlock runtime={runtime} showPatientOffice={setShowPatientOffice} />
      ) : (
        'Please wait until calibration is started'
      )}
    </div>
  );
};
