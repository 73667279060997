import { useEffect, useContext, useState } from 'react';
import { AppContext } from '../contextApp';
import { useUser } from '@clerk/clerk-react';
import { VfScenarioEnum } from '../../types/types';

import { updatePatientScenarioAccessData } from '../components/patientCard/helpers';

import { useUserMetadata } from '../hooks/useUserMetadata';

export const ExitMediaMode = ({ runtime, messageType }) => {
  const { showFixedMedia, setIsExitBioMode, setShowFixedMedia, userVfState, setUserVfState } = useContext(AppContext);
  const { user } = useUser();

  const { id: userId } = user!;
  const { userMetadata } = useUserMetadata(userId);
  useEffect(() => {
    if (showFixedMedia) {
      setIsExitBioMode(true);
      setShowFixedMedia(false);
      runtime.interact({ type: messageType, payload: '' });
    }
  }, []);
  useEffect(() => {
    if (userMetadata?.scenarioAccess) {
      updateScenarioAccessData(user, userMetadata.scenarioAccess, userVfState);
    }
  }, [userMetadata]);
  return <></>;
};

export function updateScenarioAccessData(user: any, scenarioAccessData: any, userVfState: any) {
  switch (userVfState.vfScenario) {
    case VfScenarioEnum.JOURNAL: {
      const data = {
        ...scenarioAccessData,
        journal: {
          ...scenarioAccessData.journal,
          quantity: scenarioAccessData.journal.quantity - 1,
        },
      };
      user && updatePatientScenarioAccessData(user.id, data);
      break;
    }
    case VfScenarioEnum.MEMORY: {
      const data = {
        ...scenarioAccessData,
        memory: {
          ...scenarioAccessData.memory,
          quantity: scenarioAccessData.memory.quantity - 1,
        },
      };
      user && updatePatientScenarioAccessData(user.id, data);
      break;
    }
    case VfScenarioEnum.OTHER: {
      const data = {
        ...scenarioAccessData,
        other: {
          ...scenarioAccessData.other,
          quantity: scenarioAccessData.other.quantity - 1,
        },
      };
      user && updatePatientScenarioAccessData(user.id, data);
      break;
    }
  }
}
