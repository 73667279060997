import { useState } from "react";
import { useWebSocket } from "../websocket";

export function useActiveSessions() {
    const { socket } = useWebSocket();
    
    const [sessions, setSessions] = useState({});
    socket && socket.on('sessions', (sessions) => {
        setSessions(sessions);
    });

    return { sessions }
}