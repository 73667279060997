export const RUNTIME_URL = 'https://general-runtime.voiceflow.com';
const baseUrl = import.meta.env.VF_SITE_BE_URL;
export const VF_PROXY_URL = `${baseUrl}/api/vf_proxy/`;

export enum ClassName {
  ASSISTANT_INFO = 'vfrc-assistant-info',
  AVATAR = 'vfrc-avatar',
  BUBBLE = 'vfrc-bubble',
  BUTTON = 'vfrc-button',
  CARD = 'vfrc-card',
  CAROUSEL = 'vfrc-carousel',
  CHAT = 'vfrc-chat',
  CHAT_INPUT = 'vfrc-chat-input',
  FEEDBACK = 'vfrc-feedback',
  FOOTER = 'vfrc-footer',
  HEADER = 'vfrc-header',
  ICON = 'vfrc-icon',
  IMAGE = 'vfrc-image',
  INPUT = 'vfrc-input',
  LAUNCHER = 'vfrc-launcher',
  LOADER = 'vfrc-loader',
  MESSAGE = 'vfrc-message',
  PROMPT = 'vfrc-prompt',
  SYSTEM_RESPONSE = 'vfrc-system-response',
  TIMESTAMP = 'vfrc-timestamp',
  TOOLTIP = 'vfrc-tooltip',
  TYPING_INDICATOR = 'vfrc-typing-indicator',
  USER_RESPONSE = 'vfrc-user-response',
  WIDGET = 'vfrc-widget',
}

export const DEFAULT_MESSAGE_DELAY = 1000;
export const DEFAULT_FIRST_MESSAGE_DELAY = 300;
export const DEFAULT_SENTENCE_DELAY = 1100;
export const DEFAULT_WORD_DELAY = 200;
export const COMMA_DELAY = 400;
export const DEFAULT_INDICATOR_DELAY = 2000;
