import AssistantInfo from '@/components/AssistantInfo';
import Footer from '@/components/Footer';
import Loader from '@/components/Loader';
import SystemResponse from '@/components/SystemResponse';
import UserResponse from '@/components/UserResponse';
import { ClassName } from '@/constants';
import { tagFactory } from '@/hocs';
import { CSS, fadeIn, shift, styled } from '@/styles';

const PROMPT_OVERFLOW = 10;
const SHIFT_DISTANCE = 12;

const animationStyles = ({ distance = SHIFT_DISTANCE, duration, delay }: { distance?: number; duration: number; delay: number }): CSS => ({
  opacity: 0,
  animation: [fadeIn, shift(distance)].map((animation) => `${animation} ${duration}ms ease-out ${delay}ms forwards`).join(', '),
});

const tag = tagFactory(ClassName.CHAT);

export const Overlay = styled(tag('div', 'overlay'), {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '$shadow12',
});

export const Container = styled(tag('article'), {
  position: 'relative',
  minWidth: '620px',
  display: 'flex',
  overflow: 'visible',
  flexDirection: 'column',
  justifyContent: 'left',
  alignSelf: 'left',
  maxHeight: 'calc(100vh - 8rem)',
  backgroundColor: 'transparent',

  [`& > ${Loader}`]: {
    margin: 'auto',
  },
  [`& ${Footer.Container}`]: {
    marginBottom: '3rem',
    ...animationStyles({ duration: 300, delay: 300 }),
  },

  [`& ${Overlay}`]: {
    opacity: 0,
    pointerEvents: 'none',
    trans: ['opacity'],
  },
  [` &.rtl-chat-container`]: {
    minWidth: 'unset',
    maxWidth: '30rem',
    width: '100%',
    '@media (max-width: 768px)': {
      display: 'flex',
      paddingTop: '5%',
      flexDirection: 'column',
      alignItems: 'center',
      maxHeight: '100%',
    },
    '@media (max-height: 760px)': {
      maxWidth: '26rem',
    },
  },
  variants: {
    withPrompt: {
      true: {
        [`& ${Overlay}`]: {
          opacity: 1,
          zIndex: 2,
          pointerEvents: 'auto',
        },
      },
    },
    isCurrentTurnTextOnly: {
      true: {},
      false: {
        [` &.rtl-chat-container`]: {
          '@media (max-width: 380px)  and (max-height: 670px)': {
            display: 'grid',
            gridTemplateRows: '70% auto',
            paddingTop: '1%',
            justifyContent: 'center',
            [`.rtl-footer-container`]: {
              alignSelf: 'start',
              marginTop: '3%',
            },
            [`.turn-messages-container`]: {
              marginBottom: '-2%',
            },
          },
          '@media (max-width: 360px)  and (max-height: 670px)': {
            [`.turn-messages-container`]: {
              marginBottom: '1%',
            },
          },
        },
      },
    },
  },
});

const statusStyles: CSS = {
  fontFamily: 'var(--fonts-default)',
  display: 'flex',
  justifyContent: 'left',
  fontSize: '20px',
  color: 'rgb(51, 51, 51)',
};

export const Status = styled(tag('div', 'status'), {
  ...statusStyles,
});

export const SessionTime = styled(tag('span', 'session-time'), {
  ...statusStyles,
  paddingBottom: '$1',
});

export const Dialog = styled(tag('main', 'dialog'), {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  backgroundColor: 'transparent',

  [`
    & ${UserResponse.Container},
    & ${SystemResponse.List},
    & ${SystemResponse.Actions},
    & ${AssistantInfo.Container}
  `]: {
    ...animationStyles({ duration: 150, delay: 0 }),
  },

  [`
    & > ${UserResponse.Container},
    & > ${SystemResponse.Container}
  `]: {
    padding: '0 $5',
  },

  [`& > ${SystemResponse.Actions}`]: {
    padding: '0 $5 0 54px',
  },

  [`& ${SystemResponse.Container}`]: {
    marginBottom: '$1',

    '&:last-of-type': {
      marginBottom: 0,
    },
  },

  [`& ${UserResponse.Container} + ${UserResponse.Container}`]: {
    marginTop: '$1',
  },

  [`
    & ${SystemResponse.Container} + ${UserResponse.Container},
    & ${SystemResponse.Actions} + ${UserResponse.Container},
    & ${UserResponse.Container} + ${SystemResponse.Controls} + ${SystemResponse.Container}
  `]: {
    marginTop: '$5',
  },

  [`& ${Status}`]: {
    marginTop: '$3',
    marginBottom: 8,
  },

  [` &.rtl-chat-dialog`]: {
    width: '100%',
    alignItems: 'flex-start',
    '.vfrc-system-response--message': {
      justifyContent: 'flex-start',
      width: '100%',
      color: '#333',
    },
    '@media (max-width: 768px)': {
      height: 'unset',
      '.turn-messages-container': {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'center',
      },
    },
  },
});

export const Spacer = styled(tag('div', 'spacer'), {
  flexGrow: 1,
});
