import { styled } from 'styled-components';
import { IArousalDetection } from './types';
import { RealTimeAI, SeparationLine } from './styled';
import { ContextAiOutputItem } from './styled';

export function ArousalDetectionAIBlock({ data }: { data: IArousalDetection | null }) {
  return (
    <RealTimeAI>
      <p className='ai-block-header'>Arousal Detection Agent</p>
      {data && (
        <AIBlockWrapper>
          <ContextAiOutputItem>
            <p className="item-title">Heart Rate State:</p>
            <p className="item-content">{data.heartRateState.answer}</p>
          </ContextAiOutputItem>
          <SeparationLine />
          <ContextAiOutputItem>
            <p className="item-title">Emotional State:</p>
            <p className="item-content">{data.emotionalState.answer}</p>
          </ContextAiOutputItem>
          <SeparationLine />
          <ContextAiOutputItem>
            <p className="item-title">Average HR:</p>
            <p className="item-content">{data.hrHistogramAverage}</p>
          </ContextAiOutputItem>
          <SeparationLine />
          <ContextAiOutputItem>
            <p className="item-title">Average HRV:</p>
            <p className="item-content">{data.hrvHistogramAverage}</p>
          </ContextAiOutputItem>
        </AIBlockWrapper>
      )}
    </RealTimeAI>
  );
}

export const AIBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  list-style: inside;
  div p {
    font-size: 0.65rem;
  }
`;
