import React from 'react';
import {
  CallWrapper,
  UserMediaErrorWrapper,
  UserMediaErrorInfo,
  UserMediaErrorTitle,
  UserMediaErrorButton,
  UserMediaErrorLink,
  UserMediaErrorParagraph,
} from './styled';

const refreshPage = () => {
  console.log("make sure to allow access to your microphone and camera in your browser's permissions");
  window.location.reload();
};

interface UserMediaErrorProps {}

const UserMediaError: React.FunctionComponent<UserMediaErrorProps> = () => {
  return (
    <CallWrapper>
      <UserMediaErrorWrapper>
        <UserMediaErrorInfo>
          <UserMediaErrorTitle>Camera or mic blocked</UserMediaErrorTitle>
          <UserMediaErrorButton onClick={refreshPage}>Try again</UserMediaErrorButton>
          <UserMediaErrorParagraph>
            <UserMediaErrorLink>Get help</UserMediaErrorLink>
          </UserMediaErrorParagraph>
        </UserMediaErrorInfo>
      </UserMediaErrorWrapper>
    </CallWrapper>
  );
};

export default UserMediaError;
