import { SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CameraIcon } from '../../icons';
import { breakpoints } from '../../styled';

export const CameraSelectorWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  right: 20px;
  z-index: 5;
  padding: 0 3px;
  background: #d3d3d3;
  opacity: 0.7;
  border-radius: 4px;
  @media ${breakpoints.tablet} {
    display: none;
  }
`;
export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
  }
`;
export const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  max-width: 200px;
  width: 100%;
  border-radius: 12px;
  color: #333;
  opacity: 0.8;
`;
export const CameraItem = styled.div`
  display: inline-block;
  flex-direction: row;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  direction: ltr;
  cursor: pointer;
  border-radius: 12px;
  padding: 5px;
  &.selected {
    color: #007bff;
  }
  &:hover {
    background: #f6f6f6;
  }
`;

const CameraSelector = ({ setCamera, camera, updateShenai }: { setCamera: (x: string) => void; camera: string | null; updateShenai: any }) => {
  const [videoDevicesList, setVideoDevicesList] = useState<MediaDeviceInfo[]>([]);
  const [cameraId, setCameraId] = useState<string>('');
  const [isCameraSelectorHidden, setCameraSelectorIsHidden] = useState<boolean>(false);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((res) => {
      const videoDeviceList = res?.filter((el) => {
        return el.kind === 'videoinput';
      });
      setVideoDevicesList(videoDeviceList);
    });
    if (videoDevicesList.length > 0) {
      camera ? setCameraId(camera) : setCameraId(videoDevicesList[0].deviceId);
    }
  }, [isCameraSelectorHidden]);

  const handlecameraChange = (e: any) => {
    setCameraSelectorIsHidden(false);
    setCamera(e.target.getAttribute('data-value'));
    setCameraId(e.target.getAttribute('data-value'));
    updateShenai?.selectCameraByDeviceId(e.target.getAttribute('data-value'), true);
    localStorage.setItem('cameraId', `${e.target.getAttribute('data-value')}`);
  };
  return (
    <CameraSelectorWrapper>
      {isCameraSelectorHidden && (
        <Wrapper>
          {videoDevicesList ? (
            videoDevicesList.map((device: MediaDeviceInfo, index: number) => {
              return (
                <CameraItem
                  className={device.deviceId == cameraId ? 'selected' : ''}
                  key={index}
                  data-value={device.deviceId}
                  onClick={handlecameraChange}
                >
                  {device.label}
                </CameraItem>
              );
            })
          ) : (
            <>No devices available</>
          )}
        </Wrapper>
      )}
      <IconWrapper>
        <CameraIcon onClick={() => setCameraSelectorIsHidden(!isCameraSelectorHidden)} />
      </IconWrapper>
    </CameraSelectorWrapper>
  );
};

export default CameraSelector;
