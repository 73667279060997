import styled from 'styled-components';

export const UsernameContainer = styled.div`
  position: absolute;
  z-index: 2;
  color: var(--grey);
  background-color: var(--dark-blue);
  bottom: 0;
  left: 0;
  font-size: 14px;
  padding: 0.2em 0.5em;
`;
