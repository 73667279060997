// Import required types
// import { DailyResponse } from 'daily-js';

import axios from 'axios';
import { passMeetingTokenToPatient, saveMeetingRecording } from '../../api/userAPI';

// import { passMeetingTokenToPatient } from "../../api/updatePatientsList";

// Define an interface for the room options
interface RoomOptions {
  privacy: string;
  properties: {
    exp: number;
    enable_recording: string;
  };
}

// Define the createRoom function as an async function
export const createRoom = async (therapistId: string, patientId: string) => {
  // Calculate the expiration time
  const exp: number = Math.round(Date.now() / 1000) + 60 * 30;

  // Create the room options
  const options: RoomOptions = {
    privacy: 'private',
    properties: {
      exp,
      enable_recording: 'cloud',
    },
  };

  // Determine if the endpoint is local
  const isLocal = import.meta.env.VITE_DAILY_ROOM_ENDPOINT && import.meta.env.VITE_DAILY_ROOM_ENDPOINT === 'local';

  // Set the endpoint based on the local variable
  let endpoint: string = isLocal ? 'https://api.daily.co/v1/rooms/' : `${window.location.origin}/api/rooms`;

  // Create headers if needed
  let headers: { headers: { 'Content-Type': string; Authorization: string } } | null = null;
  if (true) {
    headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer 8ee6741620e9847c4aa8ca3f31048449ddedcfde72768bde3056e0a071e4315b`,
      },
    };
  }
  // Perform the fetch request
  const { data: roomData } = await axios.post('https://api.daily.co/v1/rooms/', options, {
    ...headers,
  });

  const { data: meetingToken } = await axios.post(
    'https://api.daily.co/v1/meeting-tokens/',
    {
      properties: {
        room_name: roomData.name,
        user_id: patientId,
      },
    },
    {
      ...headers,
    }
  );

  const roomUrl = roomData.url;
  const token = meetingToken.token;

  await passMeetingTokenToPatient(therapistId, patientId, roomUrl, token);
  return { roomData, token };
};

export const saveRecording = async (sessionId: string, roomName: string) => {
  const dailyRecordingsURL = `https://api.daily.co/v1/recordings`;

  // Get the last recording in for the given Daily room
  const recordingUrl = `${dailyRecordingsURL}?room_name=${roomName}&limit=1`;
  const authConfig = {
    headers: {
      Authorization: `Bearer 8ee6741620e9847c4aa8ca3f31048449ddedcfde72768bde3056e0a071e4315b`,
    },
  };
  const recordingsResponse = await axios.get(recordingUrl, authConfig);

  // Retrieving recording data
  const recordingsData = recordingsResponse?.data;
  const recordings = recordingsData?.data;

  // Check if recordings was retrieved successfully
  if (!recordings || recordings.length === 0) {
    throw new Error('Could not fetch access link');
  }
  const firstRecording = recordings[0];
  const recordingId = firstRecording.id;

  const accessLinkURL = `${dailyRecordingsURL}/${recordingId}/access-link`;
  const accessLinkResponse = await axios.get(accessLinkURL, authConfig);
  // Check if access link was retrieved successfully
  if (!accessLinkResponse.data || !accessLinkResponse.data.download_link) {
    throw new Error('Could not fetch access link');
  }
  // Extract the download link from the response data
  const recordingLink = accessLinkResponse.data.download_link;

  await saveMeetingRecording(sessionId, recordingLink);

  return recordingLink;
};
