import { useState, useEffect } from 'react';
import { getUserMetadata } from '../api/userAPI';

export const useUserMetadata = (userId: string) => {
  const [userMetadata, setUserMetadata] = useState<UserPrivateMetadata>();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUserMetadata(userId);
      setUserMetadata(data);
    };

    fetchData();
  }, [userId]);

  return { userMetadata };
};
