import React, { useContext, useState } from 'react';

import { AppContext } from '../../contextApp';

import PatientCard from './PatientCard';
import { CloseIcon } from '../../icons';
import { CloseButton, ModalWrapper, StyledPatientCardModal } from './styled';

interface ModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const PatientCardModal: React.FC<ModalProps> = () => {
  const { openPatientCardModal, setOpenPatientCardModal } = useContext(AppContext)!;

  const onClose = () => {
    setOpenPatientCardModal({ ...openPatientCardModal, isOpen: false });
  };

  return (
    <StyledPatientCardModal isOpen={openPatientCardModal.isOpen} onClose={onClose}>
      <ModalWrapper>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        {openPatientCardModal.patientId && <PatientCard patientId={openPatientCardModal.patientId} />}
      </ModalWrapper>
    </StyledPatientCardModal>
  );
};
