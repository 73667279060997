/* eslint-disable prettier/prettier */
import './rtl.css';
import { Chat, ChatWindow, RuntimeAPIProvider, SessionStatus, SystemResponse, TurnType, TurnProps } from '../packages/react-chat';
import { MessageProps } from '../packages/react-chat/src/components/SystemResponse/types';
import { MessageType } from '../packages/react-chat/src/components/SystemResponse/constants';
import { useContext, useEffect, useState, useRef } from 'react';
import { ShenaiSDK } from 'shenai-sdk';
import { useUser } from '@clerk/clerk-react';
import DailyCoMeetComponent from './components/Daily.co';

import { CustomMessage, BY_BIO_MODE } from './custom-message.enum';
import LoadingScreen from './components/loading-screen/LoadingScreen';
import { RuntimeContext } from './context';
import { AppContext } from './contextApp';
import {
  AppContainer,
  GlobalStyle,
  DisclaimerContainer,
  TopBlock,
  LogoWrapper,
  TopSettingsBlock,
  TooLongSessionBlock,
  DefaultButton,
} from './styled';
import Logo from './components/Logo/Logo';
import { useLiveAgent } from './use-live-agent.hook';
import { useMediaTurn } from './hooks/useMediaTurn';
import { useTabFocus } from './hooks/useTabFocus';
import ProfileMenu from './components/nav-icons/profile-menu/ProfileMenu';
import SettingsMenu from './components/nav-icons/settings-menu/SettingsMenu';
import TurnMessages from './TurnMessages';
import { initShenAi } from './components/Bio/shenaiInit';
import { Notification } from './components/notification/Notification';
import { initEventLogsPolling } from './services/eventLog';
import { delay } from './utils/helpers';
import { EventLogType } from './models/eventLog';
import { useTranslation } from 'react-i18next';

import LanguageMenu from './components/languageMenu/LanguageMenu';
import MuteButton from './components/nav-icons/mute-botton/MuteButton';
import Clock from './components/clock/Clock';
import { updateVoiceflowVariables } from './utils/voiceflowVariables';
import { UserResource } from '@clerk/types';
import { UserRolesEnum } from '../types/types';
import { useWebSocket } from './websocket';
import { useAgentData } from './hooks/useAgentData';
import { PatientCardModal } from './components/patientCard/PatientCardModal';
const organisationId = import.meta.env.ORGANIZATION_ID || 'org_2bSCAADeke9ttapo5pPMqC8Gjrl';

const findOrganisationUserRole = async (user: UserResource, myValue: string) => {
  const data = await user.getOrganizationMemberships();
  for (let i = 0; i < data.data.length; i++) {
    const item = data.data[i];

    if (item.organization.id === myValue) {
      const foundElement = { role: item.role, index: i };
      console.log('Found element role in data[' + foundElement.index + ']:', foundElement.role);

      return foundElement.role;
    }
  }

  console.log('Element not found.');
  return null;
};

export const Demo: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isTimeout, setIsTimeout] = useState(false);
  const [isLoadingDone, setIsLoadingDone] = useState(false);
  const isTurnTextOnlyRef = useRef<boolean>(true);
  const { messages } = useWebSocket();

  const { t } = useTranslation();
  const { runtime } = useContext(RuntimeContext)!;

  const {
    getEventLogs,
    clearEventLogs,
    visibleMessages,
    isMuted,
    setIsMuted,
    openVideoModal,
    openPatientCardModal,
    isMessageAnimationCompleted,
    showFixedMedia,
    addEventLog,
    cameraId,
    isDailyEnabled,
    setCameraId,
    direction,
    setDirection,
    isLogsSending,
    setIsLogsSending,
    setUserVfState,
  } = useContext(AppContext)!;
  const liveAgent = useLiveAgent();
  const shenaiSDK = useRef<ShenaiSDK | null>(null);
  const { user } = useUser();
  const { i18n } = useTranslation();

  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    i18n.language == 'en' ? setIsMuted(false) : setIsMuted(true);
    setDirection(dir);

    user &&
      user.update({
        unsafeMetadata: {
          user_language: i18n.language,
        },
      });
  }, [i18n, i18n.language]);

  let launchPayload = {};
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);

  if (user) {
    launchPayload = {
      userId: user.id,
      user: {
        name: user.fullName,
      },

      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.fullName,
      emailAddress: user.primaryEmailAddress?.emailAddress,
      user_language: i18n.language,

      step: urlParams.has('step') ? urlParams.get('step')?.replace(/"/gi, '') : 'start-step',
    };
  }
  const userId = runtime.session.userID;
  const { transcriptId } = runtime;
  const logsDuration = 15 * 60 * 1000;
  useEffect(() => {
    try {
      const timeoutId = setTimeout(() => {
        setIsLogsSending(false);
        setIsTimeout(true);

        navigator.mediaDevices.getUserMedia({ video: true }).then((t) => {
          return t.getTracks().forEach((track: any) => {
            track.stop();
          });
        });
      }, logsDuration);

      return () => {
        clearTimeout(timeoutId);
      };
    } catch (err) {
      console.error('Error obtaining media stream:', err);
    }
  }, []);

  useEffect(() => {
    const initialCameraId = localStorage.getItem('cameraId');

    navigator.mediaDevices.enumerateDevices().then((res) => {
      const videoDeviceList = res?.filter((el) => {
        return el.kind === 'videoinput';
      });
      const isCameraExist = videoDeviceList.some((el) => el.deviceId == initialCameraId);
      if (!initialCameraId || !isCameraExist) {
        localStorage.setItem('cameraId', `${videoDeviceList[0].deviceId}`);
        setCameraId(videoDeviceList[0].deviceId);
      } else {
        initialCameraId && setCameraId(initialCameraId);
      }
    });
  }, []);

  // load ShenAi SDK and hide loading screen
  useEffect(() => {
    const fetchShenAiSDK = async (): Promise<void> => {
      let shenAi;
      let isShenAiLoaded = false;
      try {
        shenAi = await initShenAi();
        isShenAiLoaded = !!shenAi;
      } catch (error) {
        isShenAiLoaded = false;
      }
      const role = user && (await findOrganisationUserRole(user, organisationId));

      console.log('role: ', role);
      setUserVfState({ userRole: role });
      await runtime.launch({
        ...launchPayload,
        user_role: role,
        is_shenai_loaded: isShenAiLoaded,
      });

      await delay();

      await runtime.interact({ type: 'shenai_check', payload: isShenAiLoaded });

      shenaiSDK.current = shenAi?.shenaiSDK || null;
    };

    fetchShenAiSDK()
      .then(() => {
        setIsLoadingDone(true);
      })
      .then(() => delay(500))
      .then(() => {
        setIsLoading(false);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (transcriptId) {
      if (isLogsSending) {
        const intervalId = setInterval(() => {
          initEventLogsPolling(getEventLogs, clearEventLogs, userId, transcriptId, 2000, isLogsSending);
        }, 2000);
        return () => clearInterval(intervalId);
      }
    }
  }, [transcriptId, isLogsSending]);

  useEffect(() => {
    if (direction == 'rtl') {
      document.body.dir = 'rtl';
      document.body.style.fontFamily = "'Varela Round','Montserrat',  sans-serif";
      document.documentElement.style.setProperty('--fonts-default', "'Varela Round','Montserrat'");
    } else {
      document.body.dir = 'ltr';
      document.body.style.fontFamily = "'Montserrat', 'Varela Round', sans-serif";
      document.documentElement.style.setProperty('--fonts-default', "'Montserrat', 'Varela Round'");
    }
  }, [direction]);

  const handleEnd = () => {
    runtime.setStatus(SessionStatus.ENDED);
  };

  const handleSend = (message: string) => {
    if (liveAgent.isEnabled) {
      liveAgent.sendUserReply(message);
    } else {
      runtime.reply(message);
    }
  };

  const onMute = () => {
    setIsMuted(!isMuted);
  };

  const originalTurns = runtime?.session.turns;

  const turns = originalTurns?.filter((turn) => {
    if (turn && turn.message === BY_BIO_MODE) {
      return false;
    }
    return true;
  });

  const turnsLength = turns.length;
  const originalTurnToFadeOut = turns[turnsLength - 3] || {};
  const originalPrevTurn = turns[turnsLength - 2] || {};
  const originalCurrentTurn = turns[turnsLength - 1] || {};

  useTabFocus({
    turnId: originalCurrentTurn.id,
    onFocus: () => {
      updateVoiceflowVariables(userId, { is_tab_visible: 'true' });
      addEventLog([
        {
          type: EventLogType.TAB_VISIBILITY,
          data: {
            isVisible: true,
            turnId: originalCurrentTurn.id,
          },
        },
      ]);
    },
    onUnfocus: () => {
      updateVoiceflowVariables(userId, { is_tab_visible: 'false' });
      addEventLog([
        {
          type: EventLogType.TAB_VISIBILITY,
          data: {
            isVisible: false,
            turnId: originalCurrentTurn.id,
          },
        },
      ]);
    },
  });

  const { currentTurn, prevTurn, turnToFadeOut, fixedTurn } = useMediaTurn({
    currentTurn: originalCurrentTurn,
    prevTurn: originalPrevTurn,
    turnToFadeOut: originalTurnToFadeOut,
    turnsLength: originalTurns?.length || 0,
  });

  const isTurnTextOnly = (turn: TurnProps) =>
    turn.messages?.length
      ? turn.messages?.every((msg: MessageProps) => msg.type !== MessageType.IMAGE && msg.type !== MessageType.BIO) && !showFixedMedia
      : false;

  if (originalCurrentTurn.type !== 'user') {
    isTurnTextOnlyRef.current = isTurnTextOnly(originalCurrentTurn);
  }

  const hasAutocompleteMessage = (obj) => (Object.hasOwn(obj, 'messages') ? obj.messages.some((m) => m.type == CustomMessage.AUTOCOMPLETE) : false);
  const hasMessageType = (turn: TurnProps, messageType: MessageType) =>
    Object.hasOwn(turn, 'messages') ? turn.messages.some((m) => m.type == messageType) : false;

  const isPrevFadeOut =
    (originalCurrentTurn.type === TurnType.USER && originalPrevTurn.type === TurnType.SYSTEM && !hasAutocompleteMessage(originalPrevTurn)) ||
    // When the second system message appears
    (originalPrevTurn.type === TurnType.USER && visibleMessages.length > 1 && !hasAutocompleteMessage(originalCurrentTurn)) ||
    // When entering bio mode
    (hasMessageType(originalCurrentTurn, MessageType.BIO) && !showFixedMedia);

  const isChatInputDisabled =
    [originalCurrentTurn, originalPrevTurn].some(hasAutocompleteMessage) ||
    !isMessageAnimationCompleted ||
    originalCurrentTurn?.messages?.every((msg: MessageProps) => msg.type !== MessageType.TEXT) ||
    visibleMessages.filter((m) => m.type === MessageType.TEXT).length === 0 ||
    runtime.indicator ||
    runtime.isAction;

  if (isLoading) {
    return (
      <AppContainer>
        <LoadingScreen startFinishAnimation={isLoadingDone} />
      </AppContainer>
    );
  }

  const shouldFadeOut =
    turnToFadeOut.type === 'user' ||
    hasMessageType(turnToFadeOut, MessageType.TEXT) ||
    hasMessageType(turnToFadeOut, MessageType.BIO) ||
    hasMessageType(turnToFadeOut, MessageType.IMAGE) ||
    hasMessageType(turnToFadeOut, MessageType.AUTOCOMPLETE);
  console.log('isDailyEnabled ', isDailyEnabled);
  return (
    <AppContainer>
      <GlobalStyle />
      {!openVideoModal && (
        <TopBlock>
          <LogoWrapper href="/">
            <Logo />
          </LogoWrapper>
          <TopSettingsBlock>
            <LanguageMenu />
            <ProfileMenu />
          </TopSettingsBlock>
        </TopBlock>
      )}
      {messages.map((message, index) => message.therapistId == user?.id && <Notification key={index} message={message.content} />)}
      {isDailyEnabled && <DailyCoMeetComponent />}

      {openPatientCardModal.isOpen && <PatientCardModal />}
      {isDailyEnabled && <DailyCoMeetComponent />}
      {isTimeout ? (
        <TooLongSessionBlock>
          <div>Your session is too long</div>
          <DefaultButton onClick={() => window.location.reload()}>Reload page to start again</DefaultButton>
        </TooLongSessionBlock>
      ) : (
        //  : isDailyEnabled ? (
        //   <DailyCoMeetComponent />
        // )
        <ChatWindow.Container>
          <div className={openVideoModal ? 'video-modal-oppened chat-window-wrapper' : 'chat-window-wrapper'}>
            <RuntimeAPIProvider {...runtime}>
              <Chat
                description={''}
                avatar={''}
                withWatermark
                hasEnded={runtime.isStatus(SessionStatus.ENDED)}
                isLoading={!runtime.session.turns.length}
                onStart={runtime.launch}
                onEnd={handleEnd}
                onSend={handleSend}
                onMinimize={handleEnd}
                isInputDisabled={isChatInputDisabled}
                isCurrentTurnTextOnly={isTurnTextOnlyRef.current && !hasMessageType(originalPrevTurn, MessageType.BIO)}
              >
                {fixedTurn && <TurnMessages turn={fixedTurn} isFadeOut={!showFixedMedia} />}
                {turnsLength >= 3 && turnToFadeOut && shouldFadeOut && <TurnMessages turn={turnToFadeOut} key={turnsLength - 2} isFadeOut />}
                {turnsLength >= 2 && prevTurn && <TurnMessages turn={prevTurn} key={turnsLength - 1} isFadeOut={isPrevFadeOut} />}
                {turnsLength >= 1 && currentTurn && <TurnMessages turn={currentTurn} key={turnsLength} isLast />}
                {runtime.indicator && <SystemResponse.Indicator />}
              </Chat>
              {i18n.language == 'en' && <MuteButton onClick={onMute} isMuted={isMuted} isVideoModal={openVideoModal} />}

              {!openVideoModal && <SettingsMenu />}
            </RuntimeAPIProvider>
          </div>
          <DisclaimerContainer>
            {/* <Clock /> */}
            <span>{t('footerDisclaimer')}</span>
          </DisclaimerContainer>
        </ChatWindow.Container>
      )}
    </AppContainer>
  );
};
