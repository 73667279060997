import 'uplot/dist/uPlot.min.css';
import React, { useMemo } from 'react';
import { Heartbeat } from 'shenai-sdk';
import Uplot from 'uplot';
import UplotReact from 'uplot-react';

const chartOptions: Uplot.Options = {
  width: 320,
  height: 150,
  legend: { show: false },
  scales: {
    x: {
      time: false,
    },
  },
  series: [
    {
      label: 'Time',
      value: (_, v) => v && `${v.toFixed(2)} s`,
    },
    {
      label: 'Realtime intervals',
      stroke: 'red',
      value: (_, v) => v && `${v} ms`,
      spanGaps: true,
    },
    {
      label: 'Final intervals',
      stroke: 'blue',
      value: (_, v) => v && `${v} ms`,
      spanGaps: true,
    },
  ],
};

export const HeartbeatsPreview: React.FC<{
  realtimeBeats?: Heartbeat[] | undefined;
  finalBeats?: Heartbeat[] | undefined;
}> = ({ realtimeBeats, finalBeats }) => {
  const data = useMemo(() => {
    const b1 =
      realtimeBeats && realtimeBeats.length > 0 ? [realtimeBeats.map((b) => b.end_location_sec), realtimeBeats.map((b) => b.duration_ms)] : [[], []];
    const b2 = finalBeats && finalBeats.length > 0 ? [finalBeats.map((b) => b.end_location_sec), finalBeats.map((b) => b.duration_ms)] : [[], []];
    return Uplot.join([b1 as Uplot.AlignedData, b2 as Uplot.AlignedData]);
  }, [realtimeBeats, finalBeats]);

  return <UplotReact data={data as Uplot.AlignedData} options={chartOptions} />;
};

export default HeartbeatsPreview;
