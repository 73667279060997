import React, { useEffect, useState, useCallback, useContext } from 'react';
import DailyIframe from '@daily-co/daily-js';
import { DailyAudio, DailyProvider } from '@daily-co/daily-react';
import './DailyCo.css';
import { createRoom } from './api';
import { pageUrlFromRoomUrl } from './utils';

import Call from './Call/Call';
import Tray from './Tray/Tray';
import { AppContext } from '../../contextApp';
import { useUser } from '@clerk/clerk-react';
import { useUserMetadata } from '../../hooks/useUserMetadata';

enum DailyCoMeetComponentState {
  IDLE = 'STATE_IDLE',
  CREATING = 'STATE_CREATING',
  JOINING = 'STATE_JOINING',
  JOINED = 'STATE_JOINED',
  LEAVING = 'STATE_LEAVING',
  ERROR = 'STATE_ERROR',
  HAIRCHECK = 'STATE_HAIRCHECK',
}

export default function DailyCoMeetComponent() {
  const { patientToMeetId, isExitBioMode, setIsDailyEnabled } = useContext(AppContext);
  const [dailyCoState, setDailyCoState] = useState<DailyCoMeetComponentState>(DailyCoMeetComponentState.IDLE);
  const [roomUrl, setRoomUrl] = useState<string | null>(null);
  const [callObject, setCallObject] = useState<any>(null);
  const [apiError, setApiError] = useState<boolean>(false);
  const { user } = useUser();
  const { id: userId, organizationMemberships } = user!;
  const userRole = organizationMemberships[0].role;
  const { userMetadata } = useUserMetadata(userId);
  const [showCallComponent, setShowCallComponent] = useState<boolean>(false);

  userRole == 'org:therapist' &&
    useEffect(() => {
      if (isExitBioMode) {
        createCall().then((data) => {
          data && startHairCheck(data.url, data.token);
        });
      }
    }, [isExitBioMode]);

  userRole == 'org:member' &&
    useEffect(() => {
      if (isExitBioMode) {
        startHairCheck(userMetadata?.roomUrl, userMetadata?.meetingToken);
      }
    }, [isExitBioMode]);

  const createCall = useCallback(async () => {
    setDailyCoState(DailyCoMeetComponentState.CREATING);
    if (userRole == 'org:therapist') {
      try {
        const room = await createRoom(userId, patientToMeetId);
        const url = room.roomData.url;
        const token = room.token;
        return { url, token };
      } catch (error) {
        console.error('Error creating room', error);
        setRoomUrl(null);
        setDailyCoState(DailyCoMeetComponentState.IDLE);
        setApiError(true);
      }
    }
  }, [patientToMeetId]);

  // const startHairCheck = useCallback(
  //   async (url: any, token: any) => {
  //     const newCallObject = DailyIframe.createCallObject();
  //     setRoomUrl(url);
  //     setCallObject(newCallObject);
  //     setDailyCoState(DailyCoMeetComponentState.HAIRCHECK);
  //   },
  //   [user]
  // );
  const startHairCheck = useCallback(
    async (url: any, token: any) => {
      const newCallObject = DailyIframe.createCallObject();
      setRoomUrl(url);
      setCallObject(newCallObject);
      setDailyCoState(DailyCoMeetComponentState.HAIRCHECK);
      setShowCallComponent(true);
    },
    [user]
  );

  const startLeavingCall = useCallback(() => {
    if (!callObject) return;

    if (dailyCoState === DailyCoMeetComponentState.ERROR) {
      callObject.destroy().then(() => {
        setRoomUrl(null);
        setCallObject(null);
        setDailyCoState(DailyCoMeetComponentState.IDLE);
      });
    } else {
      setDailyCoState(DailyCoMeetComponentState.LEAVING);
      callObject.leave();
      setIsDailyEnabled(false);
    }
  }, [callObject, dailyCoState]);

  useEffect(() => {
    const pageUrl = roomUrl && pageUrlFromRoomUrl(roomUrl);
    if (pageUrl === window.location.href) return;
    window.history.replaceState(null, '', pageUrl);
  }, [roomUrl]);

  useEffect(() => {
    if (!callObject) return;

    const events = ['joined-meeting', 'left-meeting', 'error', 'camera-error'];

    function handleNewMeetingState() {
      switch (callObject.meetingState()) {
        case 'joined-meeting':
          setDailyCoState(DailyCoMeetComponentState.JOINED);
          break;
        case 'left-meeting':
          callObject.destroy().then(() => {
            setRoomUrl(null);
            setCallObject(null);
            setDailyCoState(DailyCoMeetComponentState.IDLE);
          });
          break;
        case 'error':
          setDailyCoState(DailyCoMeetComponentState.ERROR);
          break;
        default:
          break;
      }
    }

    // Use initial state
    handleNewMeetingState();

    /*
     * Listen for changes in state.
     * We can't use the useDailyEvent hook (https://docs.daily.co/reference/daily-react/use-daily-event) for this
     * because right now, we're not inside a <DailyProvider/> (https://docs.daily.co/reference/daily-react/daily-provider)
     * context yet. We can't access the call object via daily-react just yet, but we will later in Call.js and HairCheck.js!
     */
    events.forEach((event) => callObject.on(event, handleNewMeetingState));

    // Stop listening for changes in state
    return () => {
      events.forEach((event) => callObject.off(event, handleNewMeetingState));
    };
  }, [callObject]);

  const readyToCall = !apiError && isExitBioMode;
  const renderApp = () => {
    // If something goes wrong with creating the room.
    if (apiError) {
      return (
        <div>
          <h1>Error</h1>
          <p>
            Room could not be created. Check if your `.env` file is set up correctly. For more information, see the{' '}
            <a href="https://github.com/daily-demos/custom-video-daily-react-hooks#readme">readme</a>{' '}
          </p>
        </div>
      );
    }

    // No API errors? Then show the call.
    if (readyToCall) {
      return (
        <DailyProvider callObject={callObject}>
          <>
            <Call roomUrl={roomUrl ? roomUrl : typeof userMetadata?.roomUrl == 'string' ? userMetadata?.roomUrl : ''} />
            <Tray leaveCall={startLeavingCall} />
            <DailyAudio />
          </>
        </DailyProvider>
      );
    }
  };

  return <div>{renderApp()}</div>;
}
