import React, { useEffect, useState, useContext, useRef } from 'react';
import { ShenaiSDK, PrecisionMode, OperatingMode, MeasurementPreset, CameraMode, FaceState, MeasurementState } from 'shenai-sdk';

import { HeartbeatsPreview } from './HeartbeatsPreview';
import { initShenAi } from './shenaiInit';
import { type ShenaiSdkState } from './utils';
import { AppContext } from '../../contextApp';
import { delay } from '../../utils/helpers';
import ShortBioInfoBlock from './ShortBioInfoBlock';
import { EventLogType } from '../../models/eventLog';
import { postCameraFrame } from '../../services/frame';
import { updateVoiceflowVariables } from '../../utils/voiceflowVariables';
import HumeWebcam from './HumeWebcam';
import CameraSelector from '../cameraSelector/CameraSelector';
import { useTranslation } from 'react-i18next';
import {
  BioModuleCameraWrapper,
  BioModuleCanvas,
  BioModuleCard,
  BioModuleContainer,
  BioModuleShortInfoContainer,
  SkipButton,
} from './bioModule.styled';
import BioModeExtraData from './BioModuleExtraData';
import MicrophoneSelector from '../microphoneSelector/MicrophoneSelector';
import { RuntimeContext } from '../../context';
let shenaiSDK: ShenaiSDK | null = null;
let initializeSDK: () => Promise<void> = () => Promise.resolve();
let deinitializeSDK: () => void = () => {};

interface BioSettings {
  precisionMode?: keyof typeof PrecisionMode;
  operatingMode?: keyof typeof OperatingMode;
  measurementPreset?: keyof typeof MeasurementPreset;
  cameraMode?: keyof typeof CameraMode;
}

const REFRESH_INTERVAL = 500;
const POSITIONING_INTERVAL = 3 * 60 * 1000; // 3 min
const MEASURE_INTERVAL = POSITIONING_INTERVAL + 5 * 1000; // 3 min 5 sec

interface BioProps {
  onFinish: () => Promise<void>;
  userId: string;
}

const Default: React.FC<BioProps> = ({ onFinish, userId }) => {
  const [sdkState, setSdkState] = useState<ShenaiSdkState>();
  const [isBioCalibrated, setIsBioCalibrated] = useState(false);
  const [hasBioDispatch, setHasBioDispatch] = useState(false);
  const [isSimpleStream, setIsSimpleStream] = useState(false);
  const [isOneMinuteMeasure, setIsOneMinuteMeasure] = useState(false);
  const [isFrameSent, setIsFrameSent] = useState(false);
  const { t } = useTranslation();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { runtime } = useContext(RuntimeContext)!;
  const {
    setIsBioCalibrated: setIsBioCalibratedCtx,
    addEventLog,
    setFrameUrl,
    microphoneId,
    setMicrophoneId,
    cameraId,
    setCameraId,
    isLogsSending,
    setIsDailyEnabled,
    setOpenPatientCardModal,
    openPatientCardModal,
  } = useContext(AppContext);

  useEffect(() => {
    const fetchShenAiSDK = async () => {
      // create a fetch request to get the variables from VF
      const res = await fetch(`https://general-runtime.voiceflow.com/state/user/${userId}`, {
        method: 'GET',
        headers: {
          authorization: import.meta.env.VF_DM_API_KEY,
          'content-type': 'application/json',
        },
      });
      const vfData = await res.json();

      const { variables } = vfData;
      // parse the variables from VF to get the bioSettings, if it parsable
      let bioSettings: BioSettings = {};
      try {
        bioSettings = JSON.parse(variables.bio_settings);
      } catch (e) {
        bioSettings = {};
      }

      // initialize the SDK with the bioSettings from VF if they exist, default settings otherwise
      const saData = await initShenAi();

      shenaiSDK = saData?.shenaiSDK;
      initializeSDK = saData?.initializeSDK;
      deinitializeSDK = saData?.deinitializeSDK;

      function applyBioSettings() {
        // update the SDK with the new settings from VF variables if they exist, default settings otherwise
        shenaiSDK?.setPrecisionMode(shenaiSDK.PrecisionMode[bioSettings?.precisionMode || 'RELAXED']);
        shenaiSDK?.setOperatingMode(shenaiSDK.OperatingMode[bioSettings?.operatingMode || 'MEASURE']);
        shenaiSDK?.setMeasurementPreset(shenaiSDK.MeasurementPreset[bioSettings?.measurementPreset || 'INFINITE_HR']);

        shenaiSDK?.setCameraMode(shenaiSDK.CameraMode.DEVICE_ID);
        shenaiSDK?.selectCameraByDeviceId(cameraId, true);
        shenaiSDK?.setScreen(shenaiSDK?.Screen.MEASUREMENT);

        if (bioSettings?.measurementPreset && bioSettings?.measurementPreset !== 'INFINITE_HR') {
          setIsOneMinuteMeasure(true);
        } else {
          setIsOneMinuteMeasure(false);
        }
      }

      if (!window.byObj.bioIsCalibrated) {
        initializeSDK('#mxcanvas', bioSettings).then(() => {
          // the component is initialized

          applyBioSettings();

          window.byObj.bioIsCalibrated = false;
        });
      } else {
        reInitializeSDK().then(() => {
          applyBioSettings();

          window.byObj.bioIsCalibrated = false;
        });
      }
    };

    // TODO: before executing check variable in VF
    fetchShenAiSDK().catch(console.error);

    return () => {
      window.byObj.bioShowFullView = false;
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (shenaiSDK) {
        const isInitialized = shenaiSDK.isInitialized();

        if (!isInitialized) {
          setSdkState(undefined);
          return;
        }

        const newState = {
          isInitialized,

          operatingMode: shenaiSDK.getOperatingMode(),
          precisionMode: shenaiSDK.getPrecisionMode(),
          measurementPreset: shenaiSDK.getMeasurementPreset(),
          cameraMode: shenaiSDK.getCameraMode(),
          faceState: shenaiSDK.getFaceState(),

          showUserInterface: shenaiSDK.getShowUserInterface(),
          showFacePositioningOverlay: shenaiSDK.getShowFacePositioningOverlay(),
          showVisualWarnings: shenaiSDK.getShowVisualWarnings(),
          enableCameraSwap: shenaiSDK.getEnableCameraSwap(),
          showFaceMask: shenaiSDK.getShowFaceMask(),
          showBloodFlow: shenaiSDK.getShowBloodFlow(),
          enableStartAfterSuccess: shenaiSDK.getEnableStartAfterSuccess(),

          bbox: shenaiSDK.getNormalizedFaceBbox(),
          measurementState: shenaiSDK.getMeasurementState(),
          progress: shenaiSDK.getMeasurementProgressPercentage(),

          hr10s: shenaiSDK.getHeartRate10s(),
          hr4s: shenaiSDK.getHeartRate4s(),
          results: shenaiSDK.getMeasurementResults(),
          realtimeMetrics: shenaiSDK.getRealtimeMetrics(10),

          realtimeHeartbeats: shenaiSDK.getRealtimeHeartbeats(100),

          recordingEnabled: shenaiSDK.getRecordingEnabled(),

          badSignal: shenaiSDK.getTotalBadSignalSeconds(),
          signalQuality: shenaiSDK.getCurrentSignalQualityMetric(),
          ppgSignal: shenaiSDK.getFullPpgSignal(),
          faceImage: shenaiSDK.getFaceTexturePng(),
          signalImage: shenaiSDK.getSignalQualityMapPng(),
        };
        setSdkState(newState);
        addEventLog([
          {
            type: EventLogType.SHENAI_DATA,
            data: {
              ...newState,
              operatingMode: (newState.operatingMode as any)?.value,
              precisionMode: (newState.precisionMode as any)?.value,
              measurementPreset: (newState.measurementPreset as any)?.value,
              cameraMode: (newState.cameraMode as any)?.value,
              faceState: (newState.faceState as any)?.value,
              measurementState: (newState.measurementState as any)?.value,
            },
          },
        ]);

        shenaiSDK?.setShowUserInterface(false);
      }
    }, REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!isOneMinuteMeasure) {
      // if we are in infinite mode, we know we finish when we get hr10s result
      if (
        sdkState?.faceState === shenaiSDK?.FaceState.OK &&
        sdkState?.measurementState === shenaiSDK?.MeasurementState.RUNNING_SIGNAL_GOOD &&
        sdkState?.realtimeHeartbeats &&
        sdkState?.hr10s &&
        !isBioCalibrated &&
        !window.byObj.bioIsCalibrated
      ) {
        // we start to get measurements, we set the flags to true
        setIsBioCalibrated(true);
        setIsBioCalibratedCtx(true);
      }
    }
  }, [sdkState?.faceState, sdkState?.measurementState, sdkState?.realtimeHeartbeats, sdkState?.hr10s, sdkState?.measurementPreset]);

  useEffect(() => {
    if (isBioCalibrated && !hasBioDispatch) {
      // if we are calibrated and we didn't dispatch the event, we dispatch it
      // and set hasBioDispatch to true to prevent double dispatch
      setHasBioDispatch(true);
      onFinish();
    }
  }, [isBioCalibrated, hasBioDispatch]);

  useEffect(() => {
    if (isFrameSent || !getIsFaceOk(sdkState, shenaiSDK) || !isLogsSending) {
      return;
    }

    const sendFrame = async () => {
      try {
        const [res] = await postCameraFrame(userId);
        if (!res?.frame_url) return;
        const { frame_url: frameUrl } = res;
        if (!frameUrl) return;
        setFrameUrl(frameUrl);
        await updateVoiceflowVariables(userId, { frame_link: frameUrl });
      } catch (error) {
        console.error(error);
      }
      setIsFrameSent(true);
    };

    sendFrame();
  }, [sdkState?.faceState, isLogsSending]);

  function getIsFaceOk(saState?: ShenaiSdkState | null, saSDK?: ShenaiSDK | null): boolean {
    if (!saState || !saSDK) {
      return false;
    }

    switch (saState.faceState) {
      case saSDK.FaceState.OK:
      case saSDK.FaceState.TOO_CLOSE:
      case saSDK.FaceState.TOO_FAR:
      case saSDK.FaceState.NOT_CENTERED:
        return true;
      default:
        return false;
    }
  }

  async function reInitializeSDK(bioSettings = {}) {
    return delay(500)
      .then(() => {
        // reset the flags
        setIsBioCalibrated(false);
        setHasBioDispatch(false);
        setHasBioDispatch(false);
        setIsSimpleStream(false);
      })
      .then(() => delay(500))
      .then(() => {
        // deinitialize the SDK
        deinitializeSDK();
      })
      .then(() => delay(1000))
      .then(() => {
        // reset the canvas
        const newcanvas = document.createElement('canvas');
        document.getElementById('mxcanvas')?.replaceWith(newcanvas);

        newcanvas.id = 'mxcanvas';
        newcanvas.style.aspectRatio = '0.7';
        newcanvas.style.display = 'block';
        newcanvas.className = 'camera-feed';
        newcanvas.width = 322;
        newcanvas.height = 482;

        console.log('canvas', newcanvas);
      })
      .then(() => delay(2000))
      .then(() => initializeSDK('#mxcanvas', bioSettings));
  }
  const handleSkipCalibration = () => {
    updateVoiceflowVariables(userId, { skip_calibration: 'true' });
  };
  const handleJoinHumanSession = () => {
    console.log('join session');
    setIsDailyEnabled(true);
  };
  const handleViewPatientCard = () => {
    console.log('patient card');
    setOpenPatientCardModal({ ...openPatientCardModal, isOpen: true });
  };

  return (
    <BioModuleContainer key="bio">
      <BioModuleCameraWrapper>
        {shenaiSDK?.isInitialized() && <HumeWebcam userId={userId} isFrameSent={isFrameSent} />}
        <div style={{ position: 'relative' }}>
          <BioModuleCanvas is_simple_stream={isSimpleStream ? 'true' : 'false'} ref={canvasRef} id="mxcanvas" className="camera-feed" />
          <CameraSelector setCamera={setCameraId} camera={cameraId} updateShenai={shenaiSDK} />
          <MicrophoneSelector setMicrophone={setMicrophoneId} microphone={microphoneId} updateShenai={shenaiSDK} />
          {/* <div></div> */}
        </div>
      </BioModuleCameraWrapper>

      <BioModuleShortInfoContainer>
        <ShortBioInfoBlock sdkState={sdkState} shenaiSDK={shenaiSDK} />
        <BioModuleCard is_one_minute_measure={isOneMinuteMeasure ? 'true' : 'false'} className="bio-module-card heartbeat-preview-block">
          <span> {t('plotTitle')}</span>
          <HeartbeatsPreview realtimeBeats={sdkState?.realtimeHeartbeats} finalBeats={sdkState?.results?.heartbeats} />
        </BioModuleCard>
        <div style={{ display: 'flex', gap: '10px', zIndex: '1' }}>
          <SkipButton onClick={handleSkipCalibration}>Skip calibration</SkipButton>
          {/* <SkipButton onClick={handleViewPatientCard}> view patient card</SkipButton> */}
          {/* <SkipButton onClick={handleAISession}>AI Session</SkipButton> */}
        </div>
      </BioModuleShortInfoContainer>

      {isOneMinuteMeasure && <BioModeExtraData sdkState={sdkState} />}
    </BioModuleContainer>
  );
};

export default Default;
