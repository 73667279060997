import axios from 'axios';

const BASE_URL = `${import.meta.env.VF_SITE_BE_URL}/api`;

export async function updateUserStatus(id: string, status: string): Promise<any> {
  try {
    const { data } = await axios.patch(`${BASE_URL}/patient/updateUserStatus`, {
      id: id,
      status: status,
    });

    return data;
  } catch (e: any) {
    return e.response.data.message;
  }
}

export async function getUserMetadata(userId: string): Promise<any> {
  try {
    const { data } = await axios.patch(`${BASE_URL}/patient/getUserMetadata`, {
      userId: userId,
    });

    return data;
  } catch (e: any) {
    return e.response.data.message;
  }
}

export async function updatePatientsList(
  therapistId: string,
  therapistScheduleUrl: string,
  patientEmail: string,
  firstName: string,
  lastName: string
): Promise<string> {
  try {
    const { data } = await axios.patch(`${BASE_URL}/patient/updatePatientsList`, {
      therapistId: therapistId,
      therapistScheduleUrl: therapistScheduleUrl,
      patientEmail: patientEmail,
      firstName: firstName,
      lastName: lastName,
    });

    return data;
  } catch (e: any) {
    return e.response.data.message;
  }
}

export async function passMeetingTokenToPatient(therapistId: string, patientId: string, roomUrl: string, meetingToken: string): Promise<string> {
  try {
    const { data } = await axios.patch(`${BASE_URL}/patient/passMeetingToken`, {
      therapistId: therapistId,
      patientId: patientId,
      roomUrl: roomUrl,
      meetingToken: meetingToken,
    });

    return data;
  } catch (e: any) {
    return e.response.data.message;
  }
}

export async function saveMeetingRecording(sessionId: string, downloadLink: string): Promise<string> {
  try {
    const { data } = await axios.patch(`${BASE_URL}/patient/saveRecording`, {
      sessionId: sessionId,
      link: downloadLink,
    });

    return data;
  } catch (e: any) {
    return e.response.data.message;
  }
}

export async function getPatientWithSameToken(therapistId: string): Promise<string> {
  try {
    const { data } = await axios.patch(`${BASE_URL}/patient/getPatientWithSameToken`, {
      therapistId: therapistId,
    });

    return data.message;
  } catch (e: any) {
    return e.response.data.message;
  }
}
