import { styled } from 'styled-components';
import { ICalibration } from './types';
import { RealTimeAI, SeparationLine } from './styled';
import { ContextAiOutputItem } from './styled';

export function CalibrationAIBlock({ data }: { data: ICalibration | null }) {
  return (
    <RealTimeAI>
      <p className='ai-block-header'>Calibration Agent</p>
      {data && (
        <AIBlockWrapper>
          <ContextAiOutputItem>
            <p className="item-title">Stress level:</p>
            <p className="item-content">{data.stressLevel.answer}</p>
          </ContextAiOutputItem>
          <SeparationLine />
          <ContextAiOutputItem>
            <p className="item-title">Environment:</p>
            <p className="item-content">{data.privacy.answer}</p>
          </ContextAiOutputItem>
          <SeparationLine />
          <ContextAiOutputItem>
            <p className="item-title">Lighting:</p>
            <p className="item-content">{data.lighting.answer}</p>
          </ContextAiOutputItem>
          <SeparationLine />
          <ContextAiOutputItem>
            <p className="item-title">Stillness:</p>
            <p className="item-content">{data.stillness.answer}</p>
          </ContextAiOutputItem>
          <SeparationLine />
          <ContextAiOutputItem>
            <p className="item-title">Camera Angle:</p>
            <p className="item-content">{data.cameraAngle.answer}</p>
          </ContextAiOutputItem>
          <SeparationLine />
          <ContextAiOutputItem>
            <p className="item-title">Face Positioning:</p>
            <p className="item-content">{data.facePositioning.answer}</p>
          </ContextAiOutputItem>
        </AIBlockWrapper>
      )}
    </RealTimeAI>
  );
}

export const AIBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  list-style: inside;
  div p {
    font-size: 0.65rem;
  }
`;
