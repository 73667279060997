import { useContext, useEffect } from 'react';
import { useRuntime } from '../../packages/react-chat';

import { AppContext } from '../contextApp';

interface ISendFrameMessageProps {
  runtime: ReturnType<typeof useRuntime>;
}

export const SendFrameMessage = (props: ISendFrameMessageProps) => {
  const { runtime } = props;
  const { frameUrl, isLogsSending } = useContext(AppContext);

  useEffect(() => {
    isLogsSending && runtime.interact({ type: frameUrl ? 'done' : 'fail', payload: frameUrl });
  }, [isLogsSending]);

  return null;
};
