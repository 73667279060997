import { IContext } from './types';
import styled from 'styled-components';

import { ContextAiOutputItem, SeparationLine } from './styled';
import { RealTimeAI } from './styled';

export function ContextAIBlock({ data }: { data: string | null }) {
  const outputData = data && (JSON.parse(data) as IContext);

  return (
    <RealTimeAI>
      <p className='ai-block-header'>Context Agent</p>
      {outputData && (
        <ContextAIBlockWrapper>
          <ContextAiOutputItem>
            <p className="item-title">Context topics:</p>
            <p className="item-content">
              {outputData.contextTopics?.map((item, i) => {
                return <span key={i}>{` "${item}", `}</span>;
              })}
            </p>
          </ContextAiOutputItem>
          <SeparationLine />
          <ContextAiOutputItem>
            <p className="item-title">Keywords frequancy:</p>
            <ul className="item-list">
              {outputData.keywordsFrequency &&
                Object.entries(outputData.keywordsFrequency).map(([keyword, frequency]) => (
                  <li key={keyword}>
                    {keyword}: {frequency}
                  </li>
                ))}
            </ul>
          </ContextAiOutputItem>
          <SeparationLine />
          <ContextAiOutputItem>
            <p className="item-title">Summarize context:</p>
            <p className="item-content">{outputData.summarizeContext}</p>
          </ContextAiOutputItem>
          <SeparationLine />
          <ContextAiOutputItem>
            <p className="item-title">Balance evaluation:</p>
            <ul className="item-list">
              <li>Question Length: {outputData.balanceEvaluation.questionLength}</li>
              <li>Answer Length: {outputData.balanceEvaluation.answerLength}</li>
              <li>Evaluation: {outputData.balanceEvaluation.evaluation}</li>
            </ul>
          </ContextAiOutputItem>
        </ContextAIBlockWrapper>
      )}
    </RealTimeAI>
  );
}

export const ContextAIBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  list-style: inside;
`;
