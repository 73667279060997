import { useEffect } from 'react';
import { ModalProvider } from 'styled-react-modal';
import { useClerk } from '@clerk/clerk-react';

import { RuntimeProvider } from './context';
import { AppProvider } from './contextApp';
import { Demo } from './Demo';
import { WebSocketProvider } from './websocket';

export const Authenticated: React.FC = () => {
  const { signOut } = useClerk();

  useEffect(() => {
    async function register() {
      // check if the user is coming from the email invetation, signout and redirect to the home page
      const paramTicket = '__clerk_ticket';
      const url = new URL(window.location.href);
      const ticket = url.searchParams.get(paramTicket) || '';

      if (ticket) {
        try {
          signOut(() => {
            // reload page with the all the params
            window.location.href = window.location.href;
          });
        } catch (err) {
          window.location.href = '/';
        }
      }
    }

    register();
  }, []);

  return (
    <RuntimeProvider>
      <ModalProvider>
        <AppProvider>
          <WebSocketProvider>
           <Demo />
          </WebSocketProvider>
        </AppProvider>
      </ModalProvider>
    </RuntimeProvider>
  );
};
