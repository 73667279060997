import { styled } from 'styled-components';
import { ISuggestion } from './types';
import { RealTimeAI, SeparationLine } from './styled';
import { ContextAiOutputItem } from './styled';

export function SuggestionAIBlock({ data }: { data: string | null }) {
  const outputData = data && (JSON.parse(data) as ISuggestion);
  return (
    <RealTimeAI>
      <p className='ai-block-header'>Suggestion Agent</p>
      {outputData && (
        <AIBlockWrapper>
          <ContextAiOutputItem>
            <p className="item-title">Topics to Explore:</p>
            <p className="item-content">{outputData.topicsToExplore.join(', ')}</p>
          </ContextAiOutputItem>
          <SeparationLine />
          <ContextAiOutputItem>
            <p className="item-title">Topics to Expand:</p>
            <p className="item-content">{outputData.topicsToExpand.join(', ')}</p>
          </ContextAiOutputItem>
          <SeparationLine />
          <ContextAiOutputItem>
            <p className="item-title">Deepening Questions:</p>
            <p className="item-content">{outputData.deepeningQuestions.join(', ')}</p>
          </ContextAiOutputItem>
        </AIBlockWrapper>
      )}
    </RealTimeAI>
  );
}
export const AIBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  list-style: inside;
`;
