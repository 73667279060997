import { styled } from 'styled-components';
import Modal from 'styled-react-modal';

export const StyledPatientCardModal = Modal.styled`
  z-index: 10000;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;

`;
export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: white;
`;
export const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  color: #777;

  padding: 6px;
  align-self: end;
  svg {
    width: 18px;
    height: 18px;
  }
`;

export const PatientCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 500px;
  width: 500px;
  align-items: center;
  padding: 10px;
`;
export const PatientCardInfoField = styled.div`
  display: flex;
  font-size: 18px;
  gap: 20px;
`;
export const PatientUserInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
`;
export const UserImage = styled.img`
  width: 70px;
  height: 70px;
`;

export const PatientSessionsList = styled.div`
  display: flex;
  flex-direction: column;

  overflow-y: auto;

  height: 100px;
  width: 100%;
  li {
    font-size: 14px;
  }
`;
