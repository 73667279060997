import React, { createContext, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';

interface Message {
  therapistId: string;
  content: string;
}

interface WebSocketContextType {
  socket: Socket | null;
  messages: Message[];
}

interface WebSocketProviderProps {
  children: React.ReactNode;
}

const BASE_URL = `${import.meta.env.VF_SITE_BE_URL}/api/`;
const baseWsUrl = BASE_URL.replace(/^https?/, 'wss').replace(/\/api\/$/, '');    

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    const socket = io(baseWsUrl, {
      path: '/server-rt',
      withCredentials: true,
      transports: ['websocket', 'polling'],
    });

    socket.on('connect', () => {
      console.log('Connected to the WebSocket server');
    });

    socket.on('message', (message: Message) => {
      console.log('message: ', message);
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from the WebSocket server');
    });

    setSocket(socket);

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ socket, messages }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
