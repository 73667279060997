import { canvasToImageBlob } from './blob';

type Size = {
  width: number;
  height: number;
};

export class VideoRecorder {
  private videoElement: HTMLVideoElement;
  private photoElement: HTMLCanvasElement;
  private imageSize: Size;
  private cameraId: string;

  private constructor(videoElement: HTMLVideoElement, photoElement: HTMLCanvasElement, imageSize: Size, cameraId: string) {
    this.videoElement = videoElement;
    this.photoElement = photoElement;
    this.imageSize = imageSize;
    this.cameraId = cameraId;
  }

  static async create(videoElement: HTMLVideoElement, photoElement: HTMLCanvasElement, cameraId: string) {
    const mediaOptions = { audio: false, video: true };
    let mediaStream: MediaStream;

    if (cameraId) {
      mediaStream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: cameraId } } });
    } else {
      mediaStream = await navigator.mediaDevices.getUserMedia(mediaOptions);
    }

    videoElement.srcObject = mediaStream;
    videoElement.play();

    const imageSize = await VideoRecorder.setVideoSize(videoElement, photoElement);
    return new VideoRecorder(videoElement, photoElement, imageSize, cameraId);
  }

  private static async setVideoSize(videoElement: HTMLVideoElement, photoElement: HTMLCanvasElement) {
    return new Promise<Size>((resolve) => {
      videoElement.addEventListener(
        'canplay',
        () => {
          photoElement.setAttribute('width', videoElement.videoWidth.toString());
          photoElement.setAttribute('height', videoElement.videoHeight.toString());
          resolve({ width: videoElement.videoWidth, height: videoElement.videoHeight });
        },
        false
      );
    });
  }

  async takePhoto(format: string = 'image/jpeg'): Promise<Blob> {
    const context = this.photoElement.getContext('2d');

    this.photoElement.width = this.imageSize.width;
    this.photoElement.height = this.imageSize.height;
    context?.translate(this.imageSize.width, 0);
    context?.scale(-1, 1);
    context?.setTransform(1, 0, 0, 1, 0, 0);
    context?.drawImage(this.videoElement, 0, 0, this.imageSize.width, this.imageSize.height);

    return await canvasToImageBlob(this.photoElement, format);
  }

  async updateMediaStream(newCameraId: string | null) {
    if (newCameraId !== this.cameraId) {
      // Close the existing stream if it exists
      if (this.videoElement.srcObject) {
        const tracks = this.videoElement.srcObject.getTracks();
        tracks.forEach((track: any) => {
          track.stop();
        });
      }

      const mediaOptions = { audio: false, video: true };
      let mediaStream: MediaStream;

      if (newCameraId) {
        mediaStream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: newCameraId } } });
        this.cameraId = newCameraId;
      } else {
        mediaStream = await navigator.mediaDevices.getUserMedia(mediaOptions);
        this.cameraId = '';
      }

      this.videoElement.srcObject = mediaStream;
      this.videoElement.play();
    }
  }
}
