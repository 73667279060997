import { ShamefulAny } from '../../../types/types';

export enum AgentType {
  SUMMARY = 'SUMMARY',
  CALIBRATION = 'CALIBRATION',
  SUGGESTION = 'SUGGESTION',
  CONTEXT = 'CONTEXT',
  INTERPRETATION = 'INTERPRETATION',
  ACTION_DETECTION = 'ACTION_DETECTION',
  AROUSAL_DETECTION = 'AROUSAL_DETECTION',
}

export enum EventPayloadTypes {
  LIVE_LOGS = 'liveLogs',
  FRAME = 'frame',
  AI = 'AI',
}

interface AnswerWithQualityFlag {
  answer: string;
  quality_flag: number;
}

export interface AgentDataToDashboardType {
  agentType: AgentType;
  data: ShamefulAny;
  metadata?: ShamefulAny;
  startTime: number;
  timestamp: number;
  latency?: number;
}
export interface IInterpretation {
  currentStage: string;
  feelingsEstimation: string;
  opennessLevel: string;
}

export interface ICalibration {
  stressLevel: AnswerWithQualityFlag;
  privacy: AnswerWithQualityFlag;
  lighting: AnswerWithQualityFlag;
  stillness: AnswerWithQualityFlag;
  cameraAngle: AnswerWithQualityFlag;
  facePositioning: AnswerWithQualityFlag;
}

export interface IActionDetection {
  userActions: string;
  faceState: string;
  emotionalPeak: string;
}

export interface IArousalDetection {
  heartRateState: AnswerWithQualityFlag;
  emotionalState: AnswerWithQualityFlag;
  hrHistogramAverage: number;
  hrvHistogramAverage: number;
}
export interface IContext {
  contextTopics: string[];
  keywordsFrequency: { [keyword: string]: number };
  summarizeContext: string;
  balanceEvaluation: {
    questionLength: number;
    answerLength: number;
    evaluation: string;
  };
}
export interface ISuggestion {
  topicsToExplore: string[];
  topicsToExpand: string[];
  deepeningQuestions: string[];
}
