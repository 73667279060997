import { FormEvent, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { validateEmail } from '../../utils/validateEmail';
import { updatePatientsList, updateUserStatus } from '../../api/userAPI';
// import { updatePatientsList, updateUserStatus } from '../../api/updatePatientsList';
import { useUser } from '@clerk/clerk-react';
import Dropdown from '../Dropdown/Dropdown';
import { AppContext } from '../../contextApp';
import { useUserMetadata } from '../../hooks/useUserMetadata';

import PatientListBlock from '../patientList/PatientList';

import Cal, { getCalApi } from '@calcom/embed-react';
import { updateScheduleUrl } from '../patientCard/helpers';
const PatientSubmitButton = styled('button')`
  cursor: pointer;
  font-size: 1rem;
  border: none;
  outline: none;
  padding: 10px;
  background: rgb(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: background 0.1s ease-in-out;

  &:hover {
    background: rgb(0, 0, 0, 0.3);
  }
`;

const PatientFormInput = styled('input')`
  display: block;
  margin-top: 10px;
  width: 90%;
  text-decoration: none;
`;

export const FormError = styled.p`
  height: 20px;
  color: red;
  font-size: 0.8rem;
  margin-top: unset;
`;

export const TherapistOfficeButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;
export const TherapistOfficeButton = styled.button`
  display: flex;
  width: 110px;
  padding: 8px 10px;
  border: 1px solid #777;
  cursor: pointer;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
`;
const THERAPIST_SCHEDULE_URL = 'marshmallow/therapy';

const TherapistOfficeBlock = (props: { runtime: any; showTherapistOffice: any }) => {
  const { setPatientToMeetId, setOpenPatientCardModal } = useContext(AppContext);

  const { user } = useUser();
  const { id: userId } = user!;
  const { userMetadata } = useUserMetadata(userId);
  const therapistPatients = userMetadata?.patients;

  const [isPatientFormOpen, setIsPatientFormOpen] = useState(false);

  const [isPatientsListToShow, setIsPatientsListToShow] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState('');
  const [isScheduleOpen, setScheduleOpen] = useState<boolean>(false);
  const [isScheduleDropdownOpen, setIsScheduleDropdownOpen] = useState(false);
  const [patient, setPatient] = useState({
    email: '',
    firstName: '',
    lastName: '',
  });

  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [notification, setNotification] = useState('');

  const { runtime, showTherapistOffice } = props;

  const handlePatientFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validateForm = () => {
      let isValid = true;
      let newErrors = {
        firstName: '',
        lastName: '',
        email: '',
      };

      if (!patient.firstName) {
        isValid = false;
        newErrors.firstName = 'First name is required';
      }

      if (!patient.lastName) {
        isValid = false;
        newErrors.lastName = 'Last name is required';
      }

      if (patient.email == user?.emailAddresses[0].emailAddress) {
        isValid = false;
        newErrors.email = "You can't add yourself to your patients list";
      }

      if (Array.isArray(therapistPatients) && therapistPatients.some((e: any) => e.email == patient.email)) {
        isValid = false;
        newErrors.email = 'Patient is already added';
      }
      if (Array.isArray(therapistPatients)) {
        const matchingPatient = therapistPatients.find((e: any) => e.email === patient.email);
        if (matchingPatient) {
          console.log('therapistPatients', matchingPatient.id);
          updateScheduleUrl(matchingPatient.id, THERAPIST_SCHEDULE_URL);
        }
      }

      if (!patient.email) {
        isValid = false;
        newErrors.email = 'Email is required';
      } else if (!validateEmail(patient.email)) {
        isValid = false;
        newErrors.email = 'Email is not valid';
      }

      setFormErrors(newErrors);
      return isValid;
    };

    if (validateForm()) {
      const response = await updatePatientsList(userId, THERAPIST_SCHEDULE_URL, patient.email, patient.firstName, patient.lastName);

      setNotification(response);
      setPatient({ firstName: '', lastName: '', email: '' });
    }
  };

  const handleMetingWithPatientSubmit = () => {
    runtime.interact({ type: 'therapist_office_mode_done', payload: '' });
    setPatientToMeetId(selectedPatient);
    updateUserStatus(userId, 'waiting_calibration');
    showTherapistOffice(false);
  };
  const handlePatientsList = () => {
    setIsPatientsListToShow(!isPatientsListToShow);
    console.log('list');
  };

  const handleJoinSession = () => {
    console.log('interact');
    const intentPayload = {
      query: 'therapist_waiting_room',
      intent: { name: 'therapist_waiting_room' },
      confidence: 1,
    };

    runtime.interact({ type: 'intent', payload: intentPayload });
  };

  const handleTherapistSchedule = () => {
    console.log('schedule open');
    // setScheduleOpen(!isScheduleOpen);
    const emailList = therapistPatients.map((user) => {
      const encodedEmail = encodeURIComponent(user.email);
      // const params = new URLSearchParams();
      // params.append('email', encodedEmail);
      // return params.toString();
      return encodedEmail;
    });

    console.log('therapistPatients', therapistPatients);

    const baseUrl = `https://cal.com/marshmallow`;

    let url = `${baseUrl}?notes=${emailList.join('+')}`;
    console.log('URL:', url);
    window.open(url, '_blank');
  };

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({});

      cal('ui', { styles: { branding: { brandColor: '#000000' } }, hideEventTypeDetails: false, layout: 'month_view' });
    })();
  }, []);
  return (
    <div>
      Welcome to Therapist Office
      <TherapistOfficeButtonsWrapper>
        <TherapistOfficeButton onClick={() => setIsScheduleDropdownOpen((prev) => !prev)}>Create and join a meeting</TherapistOfficeButton>
        <TherapistOfficeButton onClick={handleTherapistSchedule}>Schedule</TherapistOfficeButton>
        <TherapistOfficeButton onClick={() => setIsPatientFormOpen((prev) => !prev)}>Add patient</TherapistOfficeButton>
        <TherapistOfficeButton onClick={handlePatientsList}>Patient files</TherapistOfficeButton>
      </TherapistOfficeButtonsWrapper>
      {isPatientsListToShow && <PatientListBlock />}
      <Dropdown
        isOpen={isScheduleDropdownOpen}
        options={Array.isArray(therapistPatients) ? therapistPatients : ['']}
        selectedOption={selectedPatient}
        setSelectedOption={setSelectedPatient}
        onSubmit={handleMetingWithPatientSubmit}
      />
      {isPatientFormOpen && (
        <form onSubmit={handlePatientFormSubmit} onFocus={() => setNotification('')} style={{ margin: '10px 0' }}>
          <span>Patient First Name:</span>
          <PatientFormInput
            type="text"
            value={patient.firstName}
            onChange={(e) => setPatient({ ...patient, firstName: e.target.value })}
            onFocus={() => {
              setFormErrors({ ...formErrors, firstName: '' });
            }}
          />
          {<FormError>{formErrors.firstName ? formErrors.firstName : ''}</FormError>}

          <span>Patient Last Name:</span>
          <PatientFormInput
            type="text"
            value={patient.lastName}
            onChange={(e) => setPatient({ ...patient, lastName: e.target.value })}
            onFocus={() => {
              setFormErrors({ ...formErrors, lastName: '' });
            }}
          />
          {<FormError>{formErrors.lastName ? formErrors.lastName : ''}</FormError>}

          <span>Patient Email:</span>
          <PatientFormInput
            type="text"
            value={patient.email}
            onChange={(e) => setPatient({ ...patient, email: e.target.value })}
            onFocus={() => {
              setFormErrors({ ...formErrors, email: '' });
            }}
          />
          {<FormError>{formErrors.email ? formErrors.email : ''}</FormError>}

          {notification && <p style={{ fontSize: '0.8rem' }}>{notification}</p>}
          <PatientSubmitButton type="submit">Submit</PatientSubmitButton>
        </form>
      )}
      {/* {isScheduleOpen && (
        <div>
          <h3>Schedule</h3>
          <Cal calLink="marshmallow/therapy" style={{ width: '500px', height: '400px', overflow: 'scroll' }} config={{ layout: 'month_view' }} />
        </div>
      )} */}
    </div>
  );
};
//https://cal.com/marshmallow/therapy
export default TherapistOfficeBlock;
