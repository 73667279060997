import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useParticipantIds, useScreenShare, useDailyEvent, useLocalSessionId, useDaily } from '@daily-co/daily-react';

import { CallContainer, InfoBox } from './styled';

import Tile from '../Tile/Tile';
import UserMediaError from '../UserMediaError/UserMediaError';
import Tooltip from '../../Tooltip/Tooltip';
import { useUser } from '@clerk/clerk-react';
import LiveAgentsData from '../../LiveData/LiveData';
import { RuntimeContext } from '../../../context';
import { getPatientWithSameToken, getUserMetadata } from '../../../api/userAPI';
import { useActiveSessions } from '../../../hooks/useActiveSessions';
import styled from 'styled-components';

interface CallProps {
  roomUrl: string;
}

const ParticipantWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Call: React.FunctionComponent<CallProps> = ({ roomUrl }) => {
  const [getUserMediaError, setGetUserMediaError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [patientWithSameMeetingToken, setPatientWithSameMeetingToken] = useState('');
  const { sessions } = useActiveSessions();
  const callObject = useDaily();
  const { user } = useUser();
  const { id: userId, organizationMemberships } = user!;
  const userRole = organizationMemberships[0].role;
  const firstName = user?.firstName;
  const lastName = user?.lastName;
  const ctx = useContext(RuntimeContext);
  const runtime = ctx!;
  const therapistSessionId = userRole == 'org:therapist' ? runtime.runtime.session.userID : '';

  const handleTextClick = () => {
    navigator.clipboard.writeText(roomUrl);
    setIsCopied(true);
  };

  useDailyEvent(
    'camera-error',
    useCallback(() => {
      setGetUserMediaError(true);
    }, [])
  );

  useEffect(() => {
    user?.reload().then(async () => {
      const userMetadata = await getUserMetadata(userId);

      callObject &&
        callObject.join({
          url: typeof userMetadata?.roomUrl == 'string' ? userMetadata?.roomUrl : '',
          userName: `${firstName} ${lastName}`,
          token: typeof userMetadata?.meetingToken == 'string' ? userMetadata?.meetingToken : '',
        });
    });

    if (userRole == 'org:therapist' && !patientWithSameMeetingToken) {
      getPatientWithSameToken(userId).then((patient) => {
        const onlinePatient = Object.keys(sessions).filter((session: any) => session.includes(patient))[0];
        setPatientWithSameMeetingToken(onlinePatient);
      });
    }
  }, [callObject]);

  const { screens } = useScreenShare();
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });

  const localSessionId = useLocalSessionId();

  const isAlone = remoteParticipantIds.length < 1 || screens.length < 1;

  const renderCallScreen = (): JSX.Element => (
    <CallContainer className={screens.length > 0 ? 'is-screenshare' : ''}>
      <ParticipantWrapper>
        {!localSessionId && <div>{userRole == 'org:therapist' ? 'Creating' : 'Entering'} a room, please wait</div>}
        {localSessionId && <Tile id={localSessionId} isLocal isAlone={isAlone} />}
        {localSessionId && therapistSessionId && <LiveAgentsData sessionId={therapistSessionId} />}
      </ParticipantWrapper>
      {(remoteParticipantIds.length > 0 || screens.length > 0) && (
        <ParticipantWrapper>
          {remoteParticipantIds.map((id) => (
            <Tile id={id} />
          ))}
          {userRole == 'org:therapist' && patientWithSameMeetingToken && <LiveAgentsData sessionId={patientWithSameMeetingToken} />}
          {screens.map((screen) => (
            <Tile key={screen.screenId} id={screen.session_id} isScreenShare />
          ))}
        </ParticipantWrapper>
      )}
      {remoteParticipantIds.length < 1 && screens.length < 1 && (
        <InfoBox>
          <h1>Waiting for others</h1>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p>Invite someone by sharing this</p>
            <Tooltip position="right" tooltipText={isCopied ? 'Copied' : 'Click to copy session link'}>
              <span
                style={{ color: 'blue', cursor: 'pointer', fontSize: '0.8rem', marginLeft: '0.2rem' }}
                onClick={handleTextClick}
                onMouseOut={() => setIsCopied(false)}
              >
                link
              </span>
            </Tooltip>
          </div>
        </InfoBox>
      )}
    </CallContainer>
  );

  return getUserMediaError ? <UserMediaError /> : renderCallScreen();
};

export default Call;
