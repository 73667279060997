import { useState } from 'react';
import { useRuntime } from '../../packages/react-chat';
import TherapistOfficeBlock from '../components/officeMode/TherapistOfficeBlock';

interface ISendMessageProps {
  runtime: ReturnType<typeof useRuntime>;
}

export const TherapistOfficeMode = (props: ISendMessageProps) => {
  const { runtime } = props;
  const [showTherapistOffice, setShowTherapistOffice] = useState(true);

  //   runtime.interact({ type: 'therapist_office_mode_done', payload: '' });

  return (
    <div>
      {showTherapistOffice ? (
        <TherapistOfficeBlock runtime={runtime} showTherapistOffice={setShowTherapistOffice} />
      ) : (
        'Please wait until calibration is started'
      )}
    </div>
  );
};
