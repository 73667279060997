import styled from 'styled-components';

export const UserMediaErrorWrapper = styled.div`
  grid-column: 1 / 3;
  align-items: center;
`;

export const CallWrapper = styled.div`
  grid-column: 1 / 3;
  align-items: center;
`;

export const UserMediaErrorInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UserMediaErrorTitle = styled.h1`
  color: var(--red-dark);
`;

export const UserMediaErrorButton = styled.button`
  margin: 1rem 0 1rem 0;
  background: black;
  color: var(--white);
`;

export const UserMediaErrorLink = styled.a`
  color: white;
  text-decoration: underline;

  &:visited {
    color: white;
  }
`;

export const UserMediaErrorParagraph = styled.p`
  margin: 0;
`;
