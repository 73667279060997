import { RealTimeAI } from './styled';

export function SummaryAIBlock({ data }: { data: string | null }) {
  return (
    <RealTimeAI>
      <p className='ai-block-header'>Summary Agent:</p>
      {data && <div>{data}</div>}
    </RealTimeAI>
  );
}
