import { CustomMessage } from '../custom-message.enum';
import { Trace } from './types';

export const TherapistOfficeTrace: Trace = {
  canHandle: ({ payload }) => payload?.name === 'therapist_office_mode',
  handle: ({ context }, trace) => {
    context.messages.push({ type: CustomMessage.THERAPIST_OFFICE, payload: '' });
    return context;
  },
};
