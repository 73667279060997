import { useContext, useEffect, useState } from 'react';
import { styled } from 'styled-components';

import { useUser } from '@clerk/clerk-react';
import { AppContext } from '../../contextApp';
import { updateUserStatus } from '../../api/userAPI';

import { PatientAiScenarioAccessI } from '../patientCard/types';
import { updatePatientScenarioAccessData } from '../patientCard/helpers';
import { VfScenarioEnum } from '../../../types/types';
import { useUserMetadata } from '../../hooks/useUserMetadata';

export const PatientOfficeButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;
export const PatientOfficeButton = styled.button`
  display: flex;
  width: 110px;
  padding: 8px 10px;
  border: 1px solid #777;
  cursor: pointer;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
`;
export const AiSessionButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;
export const BackButton = styled.button`
  display: flex;
  cursor: pointer;
  border: none;
  &:hover {
    color: #777;
  }
`;

const PatientOfficeBlock = (props: { runtime: any; showPatientOffice: any }) => {
  const { runtime, showPatientOffice } = props;
  const { user } = useUser();

  const { id: userId } = user!;
  const { userMetadata } = useUserMetadata(userId);

  const { setIsDailyEnabled, setUserVfState, userVfState } = useContext(AppContext);
  const [isAiSessionSelected, setIsAiSessionSelected] = useState<boolean>(false);
  const [therapistScheduleUrl, setTherapistScheduleUrl] = useState<string>();
  const [scenarioAccessData, setScenarioAccessData] = useState<PatientAiScenarioAccessI>({
    journal: {
      isAvailable: false,
      quantity: 0,
    },
    memory: {
      isAvailable: false,
      quantity: 0,
    },
    other: {
      isAvailable: false,
      quantity: 0,
    },
  });

  useEffect(() => {
    if (!userMetadata) return;
    if (userMetadata?.scenarioAccess) {
      setScenarioAccessData(userMetadata?.scenarioAccess as PatientAiScenarioAccessI);
    }
    if (userMetadata?.therapistScheduleUrl) {
      setTherapistScheduleUrl(userMetadata?.therapistScheduleUrl as string);
    }
  }, [userMetadata]);

  const handleGotoMeeting = () => {
    runtime.interact({ type: 'patient_office_mode_done', payload: '' });
    updateUserStatus(userId, 'waiting for calibration');
    showPatientOffice(false);
  };
  const handleAISession = () => {
    setIsDailyEnabled(false);

    setIsAiSessionSelected(true);
  };

  const handleOpenJournal = () => {
    setUserVfState((prevState) => ({
      ...prevState,
      vfScenario: VfScenarioEnum.JOURNAL,
    }));
    runtime.interact({ type: 'patient_office_ai_journal', payload: '' });
  };

  const handleShareMemory = () => {
    setUserVfState((prevState) => ({
      ...prevState,
      vfScenario: VfScenarioEnum.MEMORY,
    }));

    runtime.interact({ type: 'patient_office_ai_memory', payload: '' });
  };

  const handleIntake = () => {
    setUserVfState((prevState) => ({
      ...prevState,
      vfScenario: VfScenarioEnum.OTHER,
    }));
    runtime.interact({ type: 'patient_office_ai_other', payload: '' });
  };

  const handleOnScheduleClick = () => {
    const email = user!.emailAddresses[0].emailAddress;
    const encodedEmail = encodeURIComponent(email);

    const baseUrl = `https://cal.com/${therapistScheduleUrl}`;

    const params = new URLSearchParams();

    params.append('email', encodedEmail);

    const url = `${baseUrl}?${params}`;

    console.log('URL:', url);
    window.open(url, '_blank');
  };
  return (
    <div>
      Welcome to Patient Office
      {isAiSessionSelected ? (
        <div>
          <BackButton onClick={() => setIsAiSessionSelected(false)}>{`<- Back`}</BackButton>
          <AiSessionButtonsWrapper>
            <PatientOfficeButton
              disabled={!scenarioAccessData.journal || !scenarioAccessData.journal.isAvailable || scenarioAccessData.journal.quantity < 1}
              onClick={handleOpenJournal}
            >
              Journal
            </PatientOfficeButton>

            <PatientOfficeButton
              disabled={!scenarioAccessData.memory || !scenarioAccessData.memory.isAvailable || scenarioAccessData.memory.quantity < 1}
              onClick={handleShareMemory}
            >
              Share a memory
            </PatientOfficeButton>

            <PatientOfficeButton
              disabled={!scenarioAccessData.other || !scenarioAccessData.other.isAvailable || scenarioAccessData.other.quantity < 1}
              onClick={handleIntake}
            >
              NEW PATIENT INTAKE
            </PatientOfficeButton>
          </AiSessionButtonsWrapper>
        </div>
      ) : (
        <PatientOfficeButtonsWrapper>
          <PatientOfficeButton onClick={handleGotoMeeting}>Go to meeting with therapist</PatientOfficeButton>
          <PatientOfficeButton onClick={handleAISession}>Start an Ai session</PatientOfficeButton>
          {therapistScheduleUrl && <PatientOfficeButton onClick={handleOnScheduleClick}>Schedule</PatientOfficeButton>}
        </PatientOfficeButtonsWrapper>
      )}
    </div>
  );
};

export default PatientOfficeBlock;
