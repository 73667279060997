import React from 'react';
import { UsernameContainer } from './styled';
import { useUser } from '@clerk/clerk-react';
import { useParticipantProperty } from '@daily-co/daily-react';
interface UsernameProps {
  id: string;
  isLocal?: boolean;
}

const Username: React.FunctionComponent<UsernameProps> = ({ id, isLocal }: UsernameProps) => {
  const { user } = useUser();
  const firstName = user?.firstName;
  const lastName = user?.lastName;

  const userName = useParticipantProperty(id, 'user_name');

  return (
    <UsernameContainer>
      {userName ? `${userName}` : `${firstName} ${lastName}`} {isLocal && '(you)'}
    </UsernameContainer>
  );
};

export default Username;
