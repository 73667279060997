import { styled } from "styled-components";

export const RealtimeDataPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
`;

export const RealtimeDataAgents = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
`;

export const RealTimeAI = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px !important;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #777;
  padding: 3px 6px;
  color: #333;
  p.ai-block-header {
    font-size: 0.75rem;
    text-align: center;
  }
`;

export const ContextAiOutputItem = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    margin: 0 0 0.5rem;
  }
  p.item-content {
    gap: 5px;
    text-wrap: wrap;
    text-align: end;
    width: 60%;
    line-height: normal;
    margin: unset;
  }
  p.item-title {
    color: rgb(20, 97, 120);
    text-align: start;
    width: 40%;
    line-height: normal;
    margin: unset;
  }
  ul.item-list {
    display: flex;
    flex-direction: column;
  }
`;

export const SeparationLine = styled.div`
  border: 1px dashed grey;
  margin: 0.2rem 0;
`