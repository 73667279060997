import React, { useEffect, useState } from 'react';
import { PatientAiScenarioAccessI, UserPatientData } from './types';
import { PatientCardWrapper, PatientUserInfoBlock, PatientCardInfoField, UserImage, PatientSessionsList } from './styled';
import { getUserDataFromClerkByUserId, updatePatientScenarioAccessData } from './helpers';
import { useUserMetadata } from '../../hooks/useUserMetadata';

const allowedNames = {
  journal: 'journal',
  memory: 'memory',
  other: 'other',
} as const;

type AllowedScenarioName = (typeof allowedNames)[keyof typeof allowedNames];

const PatientCard = ({ patientId }: { patientId: string }) => {
  const { userMetadata } = useUserMetadata(patientId);
  const [userPatient, setUserPatient] = useState<UserPatientData | null>(null);
  const [isHomeworkBlockOpen, setIsHomeworkOpen] = useState<boolean>(false);
  const [checkboxState, setCheckboxState] = useState<PatientAiScenarioAccessI>({
    journal: {
      isAvailable: false,
      quantity: 0,
    },
    memory: {
      isAvailable: false,
      quantity: 0,
    },
    other: {
      isAvailable: false,
      quantity: 0,
    },
  });
  useEffect(() => {
    console.log('userMetadata', useUserMetadata);
    setCheckboxState(
      userMetadata?.scenarioAccess
        ? {
            journal: {
              isAvailable: userMetadata.scenarioAccess.journal.isAvailable,
              quantity: userMetadata.scenarioAccess.journal.quantity,
            },
            memory: {
              isAvailable: userMetadata.scenarioAccess.memory.isAvailable,
              quantity: userMetadata.scenarioAccess.memory.quantity,
            },
            other: {
              isAvailable: userMetadata.scenarioAccess.other.isAvailable,
              quantity: userMetadata.scenarioAccess.other.quantity,
            },
          }
        : {
            journal: {
              isAvailable: false,
              quantity: 0,
            },
            memory: {
              isAvailable: false,
              quantity: 0,
            },
            other: {
              isAvailable: false,
              quantity: 0,
            },
          }
    );
    getUserDataFromClerkByUserId(patientId).then((res) => {
      setUserPatient(res);
    });
  }, [patientId, userMetadata]);

  const handleAssignHomework = () => {
    setIsHomeworkOpen(!isHomeworkBlockOpen);
  };

  const handleQuantityChange = (name: AllowedScenarioName, quantity: number) => {
    const validatedQuantity = Math.min(10, Math.max(0, quantity));

    setCheckboxState((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], quantity: validatedQuantity },
    }));

    updatePatientScenarioAccessData(patientId, {
      ...checkboxState,
      [name]: { ...checkboxState[name], quantity: validatedQuantity },
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.currentTarget;

    setCheckboxState((prevState) => {
      const updatedState = { ...prevState };

      const nameKey = allowedNames[name as AllowedScenarioName];
      if (nameKey) {
        updatedState[nameKey] = {
          ...updatedState[nameKey],
          isAvailable: checked,
        };
      }

      updatePatientScenarioAccessData(patientId, updatedState);
      return updatedState;
    });
  };

  return (
    <PatientCardWrapper>
      {userPatient && (
        <>
          <div>
            <button onClick={handleAssignHomework}>{isHomeworkBlockOpen ? 'back to patient info' : 'Assign homework'}</button>
          </div>
          {isHomeworkBlockOpen ? (
            <>
              <div> homework block</div>
              <div>
                <div>
                  <input
                    type="checkbox"
                    id="journal"
                    name="journal"
                    value="Journal"
                    checked={checkboxState.journal.isAvailable}
                    onChange={handleCheckboxChange}
                  />
                  <input
                    type="number"
                    id="journal-quantity"
                    name="journal-quantity"
                    value={checkboxState.journal.quantity}
                    onChange={(e) => handleQuantityChange('journal', parseInt(e.target.value))}
                  />
                  <label htmlFor="journal">Journal</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="memory"
                    name="memory"
                    value="Memory"
                    checked={checkboxState.memory.isAvailable}
                    onChange={handleCheckboxChange}
                  />
                  <input
                    type="number"
                    id="memory-quantity"
                    name="memory-quantity"
                    value={checkboxState.memory.quantity}
                    onChange={(e) => handleQuantityChange('memory', parseInt(e.target.value))}
                  />
                  <label htmlFor="memory">Memory</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="other"
                    name="other"
                    value="Other"
                    checked={checkboxState.other.isAvailable}
                    onChange={handleCheckboxChange}
                  />
                  <input
                    type="number"
                    id="other-quantity"
                    name="other-quantity"
                    value={checkboxState.other.quantity}
                    onChange={(e) => handleQuantityChange('other', parseInt(e.target.value))}
                  />
                  <label htmlFor="other">Other</label>
                </div>
              </div>
            </>
          ) : (
            <PatientUserInfoBlock>
              <PatientCardInfoField>{userPatient?.clerkData.hasImage && <UserImage src={userPatient?.clerkData.imageUrl} />}</PatientCardInfoField>
              <PatientCardInfoField>
                <div>{`${userPatient?.clerkData.firstName} ${userPatient?.clerkData.lastName}`}</div>
              </PatientCardInfoField>
              <PatientCardInfoField>
                <div>{`${userPatient?.clerkData.emailAddresses[0].emailAddress}`}</div>
              </PatientCardInfoField>
              <PatientCardInfoField>
                <div>{`Latest Summary  ${userPatient?.latestSummary?.createdAt ? 'from ' + userPatient?.latestSummary?.createdAt : ''}`}</div>
                <div>{`${userPatient?.latestSummary?.summary ? userPatient?.latestSummary?.summary : userPatient?.latestSummary}`}</div>
              </PatientCardInfoField>
              <PatientCardInfoField>
                Session List:
                <PatientSessionsList>
                  {userPatient?.userTranscripts?.map((el, i) => {
                    return <li key={i}>{el.createdAt}</li>;
                  })}
                </PatientSessionsList>
              </PatientCardInfoField>
            </PatientUserInfoBlock>
          )}
        </>
      )}
    </PatientCardWrapper>
  );
};
export default PatientCard;
