export enum EventLogType {
  HEART_RATE_10S = 'HEART_RATE_10S',
  APP_STARTED = 'APP_STARTED',
  APP_ENDED = 'APP_ENDED',
  VIDEO_OPENED = 'VIDEO_OPENED',
  VIDEO_WATCHED = 'VIDEO_WATCHED',
  VIDEO_CANCELLED = 'VIDEO_CANCELLED',
  VIDEO_STARTED_PROCESSING = 'VIDEO_STARTED_PROCESSING',
  USER_MESSAGE = 'USER_MESSAGE',
  SYSTEM_MESSAGE = 'SYSTEM_MESSAGE',
  USER_FACE_EMOTIONS = 'USER_FACE_EMOTIONS',
  VF_TRANSCRIPTS = 'VF_TRANSCRIPTS',
  FEEDBACK = 'FEEDBACK',
  SHENAI_DATA = 'SHENAI_DATA',
  TAB_VISIBILITY = 'TAB_VISIBILITY',
  FOR_LIVE = 'FOR_LIVE',
}

export enum EventLogSource {
  VF = 'VF',
  MXLABS = 'MXLABS',
  HUME = 'HUME',
  FEEDBACK = 'FEEDBACK',
  CUSTOM = 'CUSTOM',
  FOR_LIVE = 'FOR_LIVE',
}

export interface EventLog {
  type: EventLogType;
  source?: EventLogSource;
  data: any;
  timestamp?: number; // In ms
}
