import React from 'react';
import { TooltipWrapper } from '../../styled';

interface TooltipProps extends React.ComponentProps<typeof TooltipWrapper> {
  children: React.ReactNode;
  tooltipText: string;
  position?: 'top' | 'bottom' | 'right' | 'left';
}

const Tooltip = ({ children, position = 'bottom', tooltipText, ...props }: TooltipProps) => {
  return (
    <TooltipWrapper className="tooltip" style={props.style}>
      {children}
      <span className={`tooltiptext ${position}`} id="tooltip-text">
        {tooltipText}
      </span>
    </TooltipWrapper>
  );
};
export default Tooltip;