import React, { useCallback, useContext, useEffect, useState } from 'react';
import {  useAudioTrack, useDaily, useLocalSessionId, useParticipantIds, useRoom, useVideoTrack } from '@daily-co/daily-react';

import './Tray.css';
import { CameraOn, Leave, CameraOff, MicrophoneOff, MicrophoneOn } from '../assets/icons';
import { useUser } from '@clerk/clerk-react';
import { saveRecording } from '../api';
import { RuntimeContext } from '../../../context';

export default function Tray({ leaveCall }: { leaveCall: any }) {
  const runtimeCtx = useContext(RuntimeContext)!;
  const sessionId = runtimeCtx.runtime.session.userID;
  const{ user } = useUser();
  const { organizationMemberships } = user!;
  const userRole = organizationMemberships[0].role;
  const callObject = useDaily();
  const room = useRoom();

  const localSessionId = useLocalSessionId();
  const localVideo = useVideoTrack(localSessionId);
  const localAudio = useAudioTrack(localSessionId);
  const [isRecording, setIsRecording] = useState(false);
  const mutedVideo = localVideo.isOff;
  const mutedAudio = localAudio.isOff;

  const participants = useParticipantIds({ filter: 'remote' });

  useEffect(() => {
    if(participants.length) {
      callObject?.startRecording({
        layout: {
          preset: 'single-participant',
          session_id: localSessionId,
        }
      })
      setIsRecording(true);
    }

    if(!participants.length && isRecording == true) {
      callObject?.stopRecording();
      saveRecording(sessionId, room?.name || '');
      setIsRecording(false);
    }
    return () => {
      callObject?.stopRecording();
      saveRecording(sessionId, room?.name || '');
    }
  }, [participants])

  const toggleVideo = useCallback(() => {
    callObject?.setLocalVideo(mutedVideo);
  }, [callObject, mutedVideo]);

  const toggleAudio = useCallback(() => {
    callObject?.setLocalAudio(mutedAudio);
  }, [callObject, mutedAudio]);

  return (
    <div className="tray">
      {/* {showMeetingInformation && <MeetingInformation />} */}
      {/*  The chat messages 'live' in the <Chat/> component's state. We can't just remove the component */}
      {/*  from the DOM when hiding the chat, because that would cause us to lose that state. So we're */}
      {/*  choosing a slightly different approach of toggling the chat: always render the component, but only */}
      {/*  render its HTML when showChat is set to true. */}

      {/*   We're also passing down the toggleChat() function to the component, so we can open and close the chat */}
      {/*   from the chat UI and not just the Tray. */}
      {/* <Chat showChat={showChat} toggleChat={toggleChat} /> */}
      <div className="tray-buttons-container">
          <button title={mutedVideo ? 'Turn camera on' : 'Turn camera off'} onClick={toggleVideo} type="button">
            {mutedVideo ? <CameraOff /> : <CameraOn />}
          </button>
          <button title={mutedAudio ? 'Unmute microphone' : 'Mute microphone'} onClick={toggleAudio} type="button">
            {mutedAudio ? <MicrophoneOff /> : <MicrophoneOn />}
          </button> 
          <button title='Leave call' onClick={leaveCall} type="button">
            <Leave /> 
          </button>
      </div>
    </div>
  );
}
