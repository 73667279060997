import styled, { keyframes } from 'styled-components';
import { breakpoints } from '../../styled';

export const BioModuleContainer = styled.div`
  box-sizing: border-box;
  font-family: var(--fonts-default);
  overflow-wrap: anywhere;
  background-color: transparent;
  font-weight: unset;
  font-size: 14px;
  line-height: 28px;
  gap: 20px;
  display: grid;
  grid-template-columns: auto auto;

  &.container-mini {
    position: relative;
    bottom: 1rem;
    max-width: 150px;
    font-size: 12px;
    width: 100%;
    left: 7rem;
  }

  @media ${breakpoints.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    height: 100%;
    gap: 10px;
    width: fit-content;
    &.container-mini {
      left: 3rem;
    }
  }
  @media (max-height: 620px) {
    gap: 10px;
  }
  @media (max-width: 360px) {
    .container-mini {
      max-width: 200px;
      left: -1rem;
    }
  }
`;

export const BioModuleCameraWrapper = styled.div`
  max-width: 322px;
  @media (max-height: 760px) {
    height: 100%;
    max-width: 100%;
  }
  @media ${breakpoints.tablet} {
    height: 100%;
    max-width: 100%;
  }
  @media (max-width: 360px) {
    height: 100%;
    max-width: 100%;
  }
`;

export const BioModuleCanvas = styled.canvas<{ is_simple_stream: string }>`
  &.camera-feed {
    display: ${(p) => (p.is_simple_stream == 'true' ? 'none' : 'block')};
    aspect-ratio: 0.7;
    border-radius: 12px;
    background-color: #000;
    max-height: 62vh;
    width: 218px;
    height: 325px;
  }
  @media (max-height: 760px) {
    &.camera-feed {
      width: 166px;
      height: 248px;
    }
  }
  @media (max-height: 620px) {
    &.camera-feed {
      width: 157px;
      height: 236px;
    }
  }
  @media ${breakpoints.tablet} {
    &.camera-feed {
      width: 100%;
      height: 400px;
    }
  }
  @media (max-width: 360px) {
    &.camera-feed {
      width: 100%;
      height: 300px;
    }
  }
`;

export const BioModuleShortInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-height: 620px) {
    gap: 10px;
  }
`;

export const BioModuleCard = styled.div<{ is_one_minute_measure?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  border-radius: 12px;
  padding: 8px 16px;
  min-width: 100%;
  width: 100%;

  padding: ${(p) => (p.is_one_minute_measure == 'true' ? '12px 16px' : '38px 16px')};

  &.heartbeat-preview-block {
    display: flex;
    flex-direction: column;
    span {
      display: inline-block;
    }
  }

  @media ${breakpoints.tablet} {
    display: grid;
    padding: 8px;
    grid-template-columns: 1fr 1fr;
    display: ${(p) => p.is_one_minute_measure == 'false' && 'none'};

    &.heartbeat-preview-block {
      display: none;
    }
  }

  @media (max-height: 760px) {
    padding: ${(p) => p.is_one_minute_measure == 'false' && '0'};
  }
`;

export const StatsBlock = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #000;
  &.stats-mini {
    display: block;
    margin-top: 10px;
    width: 280px;
  }

  @media ${breakpoints.tablet} {
    display: none;
  }
  @media (max-width: 360px) {
    &.stats-mini {
      width: 240px;
    }
  }
`;

export const BioStatusBlockShort = styled.div`
  border-radius: 12px;
  background-color: white;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 50%;
  gap: 15%;
  span {
    color: #777;
    font-size: 14px;
  }
  &.bio-status-mini {
    grid-template-columns: auto 50%;
    gap: 9%;
  }

  @media ${breakpoints.tablet} {
    gap: 8%;
  }
  @media ${breakpoints.mobile} {
    max-width: unset;
    width: 100%;
  }
  @media (max-width: 360px) {
    width: 100%;
    span {
      font-size: 12px;
    }
  }
`;
export const pulse = keyframes`
  10% {
    transform: scale(1.1);
  }
`;

export const BioStatusCard = styled.div`
  width: 100%;
  display: flex;

  &.heartbeat-block {
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    .heartbeat-block-number {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      font-size: 22px;
      gap: 3px;
      span {
        font-size: 24px;
        line-height: 24px;
      }
    }
    .heartbeat-block-number.left {
      flex-direction: row;
    }
  }
  &.bio-status-block {
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }

  .outputValue {
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-left: 10px;
    align-items: center;
  }

  .unit {
    font-size: 10px !important;
    align-self: self-end;
    line-height: 10px !important;
  }

  .circle {
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 5px solid #b7b7b7;
    animation: pulse 1s linear infinite;
  }

  .idle {
    border-color: #fcd965;
  }

  .error {
    border-color: #df6569;
  }

  .success {
    border-color: #90c575;
  }

  .disabled,
  .disabled div {
    color: #b7b7b7;
    fill: #b7b7b7;
    animation: none;
  }

  .heartIcon {
    width: 32px;
    height: 32px;
    fill: #df6569;
    animation: ${pulse} 1s infinite;
  }

  @media ${breakpoints.tablet} {
    .outputValue {
      text-wrap: nowrap;
      margin-left: 0px;
      justify-self: flex-end;
    }
  }

  @media (max-width: 360px) {
    .heartbeat-block-number {
      span {
        font-size: 10px;
      }
      span:first-child {
        font-size: 16px;
      }
    }
  }
`;
export const SkipButton = styled.button`
  border-radius: 12px;
  background-color: white;
  padding: 8px 16px;
  cursor: pointer;
  border: none;
  color: #333;
  width: fit-content;
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  &:hover,
  &:focus {
    background: #fff;
    color: #777;
    text-decoration: underline;
  }
`;
