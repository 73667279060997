import styled from 'styled-components';

export const TileContainer = styled.div`
  position: relative;
  width: 480px;
  height: 270px;

  @media only screen and (max-width: 600px) {
    width: 240px;
    height: 135px;
  }

  &.self-view {
    width: 480px;
    height: 270px;
  }

  @media only screen and (max-width: 600px) {
    &.self-view {
      width: 240px;
      height: 135px;
    }
  }

  &.no-video {
    background-color: var(--dark-blue-border);
  }

  &.no-video video {
    visibility: hidden;
  }

  &.self-view.alone {
    width: 480px;
    height: 270px;
  }

  @media only screen and (max-width: 600px) {
    &.self-view.alone {
      width: 240px;
      height: 135px;
    }
  }

  &.tile-screenshare {
    grid-column: 1 / -1;
    grid-row: 1;
  }
`;
