import { ErrorBoundary } from '@sentry/react';
import { useAgentData } from '../../hooks/useAgentData';
import ErrorBoundaryFallback from '../ErrorBoundary/ErrorBoundaryFallback';
import { SummaryAIBlock } from './SummaryAIBlock';
import { ContextAIBlock } from './ContextAIBlock';
import { InterpretationAIBlock } from './InterpretationAIBlock';
import { CalibrationAIBlock } from './CalibrationAIBlock';
import { ActionDetectionAIBlock } from './ActionDetectionAIBlock';
import { ArousalDetectionAIBlock } from './ArousalDetectionAIBlock';
import { SuggestionAIBlock } from './SuggestionAIBlock';
import { RealtimeDataAgents, RealtimeDataPageWrapper } from './styled';

const LiveAgentsData = ({ sessionId }: { sessionId: string }) => {
  const { summary, calibration, actionDetection, arousalDetection, suggestion, context, interpretation } = useAgentData(sessionId.sessionId);
  const noData = !summary && !calibration && !actionDetection && !arousalDetection && !suggestion && !context && !interpretation;

  return (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <RealtimeDataPageWrapper>
        <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
          {noData && <p>Fetching agents data...</p>}
          <RealtimeDataAgents>
            {summary && (
              <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
                <SummaryAIBlock data={summary} />
              </ErrorBoundary>
            )}
            {suggestion && (
              <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
                <SuggestionAIBlock data={suggestion} />
              </ErrorBoundary>
            )}
            {context && (
              <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
                <ContextAIBlock data={context} />
              </ErrorBoundary>
            )}
            {calibration && (
              <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
                <CalibrationAIBlock data={calibration} />
              </ErrorBoundary>
            )}
            {actionDetection && (
              <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
                <ActionDetectionAIBlock data={actionDetection} />
              </ErrorBoundary>
            )}
            {arousalDetection && (
              <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
                <ArousalDetectionAIBlock data={arousalDetection} />
              </ErrorBoundary>
            )}
            {interpretation && (
              <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
                <InterpretationAIBlock data={interpretation} />
              </ErrorBoundary>
            )}
          </RealtimeDataAgents>
        </ErrorBoundary>
      </RealtimeDataPageWrapper>
    </ErrorBoundary>
  );
};

export default LiveAgentsData;
