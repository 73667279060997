import { useState } from "react";
import { ShamefulAny } from "../../types/types";
import { AgentType, EventPayloadTypes, IActionDetection, IArousalDetection, ICalibration } from "../components/LiveData/types";
import { useWebSocket } from "../websocket";

export function useAgentData(sessionId: string): {
    summary: string | null;
    calibration: ICalibration | null;
    suggestion: string | null;
    context: string | null;
    interpretation: string | null;
    actionDetection: IActionDetection | null;
    arousalDetection: IArousalDetection | null;
  } {
    const [summary, setSummary] = useState<string | null>(null);
    const [calibration, setCalibration] = useState<ICalibration | null>(null);
    const [actionDetection, setActionDetection] = useState<IActionDetection | null>(null);
    const [arousalDetection, setArousalDetection] = useState<IArousalDetection | null>(null);
    const [suggestion, setSuggestion] = useState<ShamefulAny | null>(null);
    const [context, setContext] = useState<string | null>(null);
    const [interpretation, setInterpretation] = useState<string | null>(null);

    const { socket } = useWebSocket();
  
    socket?.on(sessionId, (payload: any) => {
      if (payload.type === EventPayloadTypes.AI) {
        const data = payload.data[0];
  
        switch (data.agentType) {
          case AgentType.SUMMARY:
            setSummary(data.data as string);
            break;
          case AgentType.CALIBRATION:
            setCalibration(data.data);
            break;
          case AgentType.ACTION_DETECTION:
            setActionDetection(data.data);
            break;
          case AgentType.AROUSAL_DETECTION:
            setArousalDetection(data.data);
            break;
          case AgentType.SUGGESTION:
            setSuggestion(data.data);
            break;
          case AgentType.CONTEXT:
            setContext(data.data);
            break;
          case AgentType.INTERPRETATION:
            setInterpretation(data.data);
            break;
        }
      }
    });
  
    return {
      summary,
      calibration,
      suggestion,
      context,
      interpretation,
      actionDetection,
      arousalDetection,
    };
  }
  