import React, { useEffect, useState } from 'react';
import './Notification.css';

type NotificationProps = {
    message: string
}

export const Notification = ({ message }: NotificationProps) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
        }, 5000); // Hide after 5 seconds

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`notification ${!visible ? 'hidden' : ''}`}>
            {message}
        </div>
    );
};
