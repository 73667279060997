import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useUser } from '@clerk/clerk-react';
import { AppContext } from '../../contextApp';
import { getUserDataFromClerkByUserId } from '../patientCard/helpers';
import { useUserMetadata } from '../../hooks/useUserMetadata';

const PatientListBlock = () => {
  const { setOpenPatientCardModal, openPatientCardModal } = useContext(AppContext);
  const { user } = useUser();
  const { id: userId } = user!;
  const { userMetadata } = useUserMetadata(userId);
  const therapistPatients = userMetadata?.patients;

  const handlePatientClick = (el: { id: any }) => {
    setOpenPatientCardModal({ isOpen: true, patientId: el.id });
    console.log(el.id);
  };

  const [patientNames, setPatientNames] = useState<{ id: string; name: string | undefined }[]>([]);

  const getName = (patientId: string) => {
    const name = getUserDataFromClerkByUserId(patientId).then((res) => `${res.clerkData.firstName} ${res.clerkData.lastName}`);
    return name;
  };

  useEffect(() => {
    if (!therapistPatients) return;
    const fetchPatientNames = async () => {
      const names = await Promise.all(
        therapistPatients?.map(async (patient: { id: string }) => {
          return { id: patient.id, name: await getName(patient.id) };
        })
      );
      setPatientNames(names);
    };
    fetchPatientNames();
  }, [therapistPatients]);

  return (
    <div>
      The list of patients:
      {patientNames.map((el, i) => {
        return (
          <div style={{ cursor: 'pointer' }} key={i} onClick={() => handlePatientClick(el)}>
            {el.name || 'Loading...'}
          </div>
        );
      })}
    </div>
  );
};

export default PatientListBlock;

export const PatientListWrapper = styled.div``;
