import axios from 'axios';
const BASE_URL = `${import.meta.env.VF_SITE_BE_URL}/api`;
export async function getUserDataFromClerkByUserId(userId: string) {
  console.log(userId);
  try {
    const response = await axios.get(`${BASE_URL}/user`, {
      params: { userId },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function updatePatientScenarioAccessData(userId: string, patientData: any) {
  console.log(userId);
  try {
    const response = await axios.post(`${BASE_URL}/user/scenario-access`, {
      params: { userId },
      patientData: patientData,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
export async function updateScheduleUrl(userId: string, therapistScheduleUrl: string) {
  console.log(userId);
  try {
    const response = await axios.post(`${BASE_URL}/user/update-schedule-url`, {
      params: { userId },
      therapistScheduleUrl: therapistScheduleUrl,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
