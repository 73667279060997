import { styled } from 'styled-components';
import { IInterpretation } from './types';
import { RealTimeAI, SeparationLine } from './styled';
import { ContextAiOutputItem } from './styled';

export function InterpretationAIBlock({ data }: { data: string | null }) {
  const outputData = data && (JSON.parse(data) as IInterpretation);
  return (
    <RealTimeAI>
      <p className='ai-block-header'>Interpretation Agent</p>
      {outputData && (
        <AIBlockWrapper>
          <ContextAiOutputItem>
            <p className="item-title">Current stage:</p>
            <p className="item-content">{outputData.currentStage}</p>
          </ContextAiOutputItem>
          <SeparationLine />
          <ContextAiOutputItem>
            <p className="item-title">Feelings estimation:</p>
            <p className="item-content">{outputData.feelingsEstimation}</p>
          </ContextAiOutputItem>
          <SeparationLine />
          <ContextAiOutputItem>
            <p className="item-title">Oppeness level:</p>
            <p className="item-content">{outputData.opennessLevel}</p>
          </ContextAiOutputItem>
        </AIBlockWrapper>
      )}
    </RealTimeAI>
  );
}

export const AIBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  list-style: inside;
`;
