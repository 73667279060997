import { styled } from 'styled-components';
import { IActionDetection } from './types';
import { RealTimeAI, SeparationLine } from './styled';
import { ContextAiOutputItem } from './styled';

export function ActionDetectionAIBlock({ data }: { data: IActionDetection | null }) {
  return (
    <RealTimeAI>
      <p className='ai-block-header'>Action Detection Agent</p>
      {data && (
        <AIBlockWrapper>
          <ContextAiOutputItem>
            <p className="item-title">User Actions:</p>
            <p className="item-content">{data.userActions}</p>
          </ContextAiOutputItem>
          <SeparationLine />
          <ContextAiOutputItem>
            <p className="item-title">Face State:</p>
            <p className="item-content">{data.faceState}</p>
          </ContextAiOutputItem>
          <SeparationLine />
          <ContextAiOutputItem>
            <p className="item-title">Emotional State:</p>
            <p className="item-content">{data.emotionalPeak}</p>
          </ContextAiOutputItem>
        </AIBlockWrapper>
      )}
    </RealTimeAI>
  );
}

export const AIBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  list-style: inside;
  div p {
    font-size: 0.65rem;
  }
`;
