import { ShenaiSDK } from 'shenai-sdk';
import { TurnProps, TurnType } from '../../packages/react-chat';
import { MessageType } from '@/components/SystemResponse/constants';

import { postEventLogs } from '../api/eventLog';
import { EventLogType, EventLog, EventLogSource } from '../models/eventLog';

const hydrateShenAIData = (data: any) => {
  const {
    isInitialized,
    operatingMode,
    precisionMode,
    measurementState,
    cameraMode,
    faceState,
    hr10s,
    hr4s,
    realtimeHeartbeats,
    badSignal,
    signalQuality,
  } = data;

  return {
    isInitialized,
    operatingMode,
    precisionMode,
    measurementState,
    cameraMode,
    faceState,
    hr10s,
    hr4s,
    realtimeHeartbeats,
    badSignal,
    signalQuality,
  };
};

export const initMeasurementLogs = (
  shenaiSDK: ShenaiSDK | null,
  addEventLogDispatch: (_: EventLog) => void,
  delay: number = 1000
): ReturnType<typeof setInterval> => {
  return setInterval(() => {
    if (!shenaiSDK) {
      return;
    }

    if (document.hidden) {
      return;
    }

    const isInitialized = shenaiSDK.isInitialized();

    const data = {
      isInitialized,

      operatingMode: (shenaiSDK.getOperatingMode() as any)?.value,
      precisionMode: (shenaiSDK.getPrecisionMode() as any)?.value,
      measurementPreset: (shenaiSDK.getMeasurementPreset() as any)?.value,
      cameraMode: (shenaiSDK.getCameraMode() as any)?.value,
      faceState: (shenaiSDK.getFaceState() as any)?.value,

      showUserInterface: shenaiSDK.getShowUserInterface(),
      showFacePositioningOverlay: shenaiSDK.getShowFacePositioningOverlay(),
      showVisualWarnings: shenaiSDK.getShowVisualWarnings(),
      enableCameraSwap: shenaiSDK.getEnableCameraSwap(),
      showFaceMask: shenaiSDK.getShowFaceMask(),
      showBloodFlow: shenaiSDK.getShowBloodFlow(),
      enableStartAfterSuccess: shenaiSDK.getEnableStartAfterSuccess(),

      bbox: shenaiSDK.getNormalizedFaceBbox(),
      measurementState: (shenaiSDK.getMeasurementState() as any)?.value,
      progress: shenaiSDK.getMeasurementProgressPercentage(),

      hr10s: shenaiSDK.getHeartRate10s(),
      hr4s: shenaiSDK.getHeartRate4s(),
      results: shenaiSDK.getMeasurementResults(),
      realtimeMetrics: shenaiSDK.getRealtimeMetrics(10),

      realtimeHeartbeats: shenaiSDK.getRealtimeHeartbeats(100),

      recordingEnabled: shenaiSDK.getRecordingEnabled(),

      badSignal: shenaiSDK.getTotalBadSignalSeconds(),
      signalQuality: shenaiSDK.getCurrentSignalQualityMetric(),
      faceImage: shenaiSDK.getFaceTexturePng(),
      signalImage: shenaiSDK.getSignalQualityMapPng(),
    };

    const log: EventLog = {
      type: EventLogType.SHENAI_DATA,
      data: hydrateShenAIData(data),
    };

    if (isInitialized) {
      addEventLogDispatch([log]);
    }
  }, delay);
};

const logToLive = (log) => {
  const type = log.type;
  switch (type) {
    case EventLogType.SHENAI_DATA:
      return {
        hr4s: log.data.hr4s,
        faceState: log.data.faceState,
        measurementState: log.data.measurementState,
        sygnalQuality: log.data.sygnalQuality,
      };
    case EventLogType.USER_FACE_EMOTIONS:
      return {
        topEmotions: log.data.topEmotions,
      };
    case EventLogType.TAB_VISIBILITY:
      return {
        isVisible: log.data.isVisible,
      };

    default:
      return [];
  }
};

export const initEventLogsPolling = async (
  getEventLogs: () => EventLog[],
  clearEventLogs: () => void,
  userId: string,
  transcriptId: string,
  delay: number = 2000,
  isLogsSending: boolean
) => {
  try {
    const logs: EventLog[] = getEventLogs();

    const logForLive = logs.map((log) => ({
      ...log,
      data: logToLive(log),
      source: EventLogSource.FOR_LIVE,
    }));

    await postEventLogs({
      user_id: userId,
      transcript_id: transcriptId,
      logs: logForLive,
      json_obj: JSON.stringify(logs),
    });

    if (!document.hidden && isLogsSending) {
      const logsWithSource = logs.map((log) => ({
        ...log,
        source: getEventSourceByType(log.type),
      }));

      await postEventLogs({
        user_id: userId,
        transcript_id: transcriptId,
        logs: logsWithSource,
        json_obj: JSON.stringify(logs),
      });
    }

    clearEventLogs();
  } catch (error) {
    console.error('Error in initEventLogsPolling:', error);
  }
};

export const turnToEventLogs = (turn?: TurnProps): EventLog[] => {
  if (!turn) {
    return [];
  }

  if (turn.type === TurnType.USER) {
    return [
      {
        type: EventLogType.USER_MESSAGE,
        data: {
          message: turn.message,
        },
      },
    ];
  }

  const messages =
    turn.messages
      ?.filter((message) => message.type === MessageType.TEXT)
      .reduce(
        (acc, message) => [
          ...acc,
          ...message.text.reduce(
            (acc, textObj) => [...acc, ...(textObj.children?.filter((child) => removeSpecialChars(child.text).trim().length > 0) || [])],
            []
          ),
        ],
        []
      ) || [];
  return messages.map(({ text }: { text: string }) => ({
    type: EventLogType.SYSTEM_MESSAGE,
    data: {
      message: text,
    },
  }));
};

function removeSpecialChars(str: string): string {
  return str && str.replace(/\"|&nbsp;|\[\[.*?\]\]/gi, '');
}

function getEventSourceByType(eventType: EventLogType) {
  switch (eventType) {
    case EventLogType.HEART_RATE_10S:
    case EventLogType.SHENAI_DATA:
      return EventLogSource.MXLABS;
    case EventLogType.VF_TRANSCRIPTS:
      return EventLogSource.VF;
    case EventLogType.USER_FACE_EMOTIONS:
      return EventLogSource.HUME;
    case EventLogType.FEEDBACK:
      return EventLogSource.FEEDBACK;
    case EventLogType.FOR_LIVE:
      return EventLogSource.FOR_LIVE;
    default:
      return EventLogSource.CUSTOM;
  }
}
