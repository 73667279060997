import React from 'react';
import './Dropdown.css'; // Assuming the CSS is in a file named Dropdown.css

interface DropdownProps {
  isOpen: boolean;
  options: any[];
  selectedOption: string;
  setSelectedOption: (email: string) => void;
  onSubmit: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({ isOpen, options, selectedOption, setSelectedOption, onSubmit }) => {
  return (
    isOpen && (
      <div className="dropdown-container">
        <label htmlFor="patient-select" className="dropdown-label">
          Choose a patient:
        </label>
        <select id="patient-select" className="dropdown-select" value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
          <option value="" disabled>
            --Please choose an option--
          </option>
          {Array.isArray(options) &&
            options.map((patient, index) => (
              <option key={index} value={patient.id}>
                {patient.firstName && patient.lastName ? `${patient.firstName} ${patient.lastName}` : 'Anonymous'}
              </option>
            ))}
        </select>
        {selectedOption && (
          <div className="button-container">
            <button className="meeting-button" onClick={onSubmit} disabled={!selectedOption}>
              Go to meeting with patient
            </button>
          </div>
        )}
      </div>
    )
  );
};

export default Dropdown;
