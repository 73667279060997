import React, { useEffect, useState } from 'react';
import { ShenaiSDK } from 'shenai-sdk';
import { BY_BIO_MODE } from '../../custom-message.enum';
import { useRuntime } from '../../../packages/react-chat/src/hooks/useRuntime';
import { initShenAi } from './shenaiInit';
import { type ShenaiSdkState } from './utils';
import ShortBioInfoBlock from './ShortBioInfoBlock';
import { BioModuleContainer, StatsBlock } from './bioModule.styled';

let shenaiSDK: ShenaiSDK | null = null;

interface BioMiniProps {
  runtime: ReturnType<typeof useRuntime>;
}

const Mini: React.FC<BioMiniProps> = ({ runtime }) => {
  const [sdkState, setSdkState] = useState<ShenaiSdkState | null>();

  useEffect(() => {
    const fetchShenAiSDK = async () => {
      const saData = await initShenAi();
      shenaiSDK = saData?.shenaiSDK || null;
    };

    fetchShenAiSDK().catch(console.error);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (shenaiSDK) {
        const isInitialized = shenaiSDK.isInitialized();

        if (!isInitialized) {
          setSdkState(undefined);
          return;
        }

        const newState = {
          isInitialized,

          operatingMode: shenaiSDK.getOperatingMode(),
          precisionMode: shenaiSDK.getPrecisionMode(),
          measurementPreset: shenaiSDK.getMeasurementPreset(),
          cameraMode: shenaiSDK.getCameraMode(),
          faceState: shenaiSDK.getFaceState(),

          showUserInterface: shenaiSDK.getShowUserInterface(),
          showFacePositioningOverlay: shenaiSDK.getShowFacePositioningOverlay(),
          showVisualWarnings: shenaiSDK.getShowVisualWarnings(),
          enableCameraSwap: shenaiSDK.getEnableCameraSwap(),
          showFaceMask: shenaiSDK.getShowFaceMask(),
          showBloodFlow: shenaiSDK.getShowBloodFlow(),
          enableStartAfterSuccess: shenaiSDK.getEnableStartAfterSuccess(),

          bbox: shenaiSDK.getNormalizedFaceBbox(),
          measurementState: shenaiSDK.getMeasurementState(),
          progress: shenaiSDK.getMeasurementProgressPercentage(),

          hr10s: shenaiSDK.getHeartRate10s(),
          hr4s: shenaiSDK.getHeartRate4s(),
          results: shenaiSDK.getMeasurementResults(),
          realtimeMetrics: shenaiSDK.getRealtimeMetrics(10),

          realtimeHeartbeats: shenaiSDK.getRealtimeHeartbeats(100),

          recordingEnabled: shenaiSDK.getRecordingEnabled(),

          badSignal: shenaiSDK.getTotalBadSignalSeconds(),
          signalQuality: shenaiSDK.getCurrentSignalQualityMetric(),
          faceImage: shenaiSDK.getFaceTexturePng(),
          signalImage: shenaiSDK.getSignalQualityMapPng(),
        };
        setSdkState(newState);

        shenaiSDK?.setShowUserInterface(false);
      }
    }, 200);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (
      sdkState?.faceState === shenaiSDK?.FaceState.OK &&
      sdkState?.measurementState === shenaiSDK?.MeasurementState.RUNNING_SIGNAL_GOOD &&
      sdkState?.realtimeHeartbeats &&
      sdkState?.hr10s
    ) {
      // TODO: save data and send as a batch, or send data to backend directly
      // console.log('Bio Mini send data: ', `${sdkState?.hr10s} bpm`);
    }
  }, [sdkState?.faceState, sdkState?.measurementState, sdkState?.realtimeHeartbeats, sdkState?.hr10s]);

  function sendCommand() {
    runtime.reply(BY_BIO_MODE);
  }

  return (
    <BioModuleContainer className={` bio-mini container-mini`}>
      <StatsBlock className="stats-mini">
        <ShortBioInfoBlock sdkState={sdkState} shenaiSDK={shenaiSDK} isMini={true} />
      </StatsBlock>
    </BioModuleContainer>
  );
};

export default Mini;
