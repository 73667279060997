function roomUrlFromPageUrl(): string | null {
  const match = window.location.search.match(/roomUrl=([^&]+)/i);
  return match && match[1] ? decodeURIComponent(match[1]) : null;
}

function pageUrlFromRoomUrl(roomUrl?: string): string {
  return window.location.href.split('?')[0] + (roomUrl ? `?roomUrl=${encodeURIComponent(roomUrl)}` : '');
}

export { roomUrlFromPageUrl, pageUrlFromRoomUrl };
